import React, { Component } from "react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { AiOutlineQuestionCircle } from "react-icons/all";
import { BiSupport } from "react-icons/bi";
import { BsChatDotsFill } from "react-icons/bs";

class FloatingButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    ChatWithUsHandle = () =>{
        window.open("https://wa.me/6282246780022", "_blank");
    }

    TalkToUsHandle = () =>{
        window.open("tel:+622129222999");
    }

    render() {

        return (
            <div>
                <Fab
                mainButtonStyles={{ backgroundColor: "#FF9800" }}
                actionButtonStyles={{ backgroundColor: "#9a2b59" }}
                position={{ bottom: 4, right: 4 }}
                icon={<AiOutlineQuestionCircle />}
                event={"hover"}
                >
                <Action
                    text="Talk to Us"
                    children={<BiSupport />}
                    style={{ backgroundColor: "#03A9F4" }}
                    onClick={this.TalkToUsHandle}
                />
                <Action
                    text="Chat with Us"
                    children={<BsChatDotsFill />}
                        style={{ backgroundColor: "#4caf50" }}
                    onClick={this.ChatWithUsHandle}
                >
                </Action>
                </Fab>
            </div>
        );
    }
}
 
export default FloatingButton;