import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import jumbtronBg from "../../components/bg-city.webp";
import FacilityCard from "./components/FacilityCard";
import { apiCaller } from "../../services/apiCaller";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";

import Select from "react-select";
// import { colourOptions, groupedOptions } from '../data';

class FacilitiesBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilities: {},
      isLoading: true,
      locations: {},
      locationLoading: true,
      minimumInputCheck: false,
      user: JSON.parse(localStorage.getItem("userData")),
    };

    // this.getFacilities = this.getFacilities.bind(this);
  }

  getFacilities(query = null) {
    let url = `/getFacilitiesOpen`;
    if (query) {
      url += `?q=${query}`;
    }
    if (this.state.user.client_id == 1014180) {
      url += `?q=109`;
    }
    if (this.state.user.client_id == 1018891) {
      url += `?q=96`;
    }
    if (this.state.user.client_id == 1019310) {
      url += `?q=104`;
    }
    this.setState({
      isLoading: true,
    });

    apiCaller
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({
          isLoading: false,
          facilities: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  }

  displayFacilityCard() {
    const facilitiesArray = Object.entries(this.state.facilities);

    let facilityCard = [];

    facilitiesArray.map((v) => {
      if (
        v[1].facility_name !== "Hello Live" &&
        v[1].facility_name !== "Live Box"
      ) {
        const operationalHours = `${v[1].start_time
          .toString()
          .slice(0, -2)}:00 - ${v[1].end_time.toString().slice(0, -2)}:00`;
        return facilityCard.push(
          <FacilityCard
            key={v[1].id}
            locationName={v[1].location_name}
            facilityName={v[1].facility_name}
            facilityType={v[1].facility_type}
            address={v[1].address}
            minPax={v[1].min_pax}
            maxPax={v[1].max_pax}
            facilityImage={v[1].images[0]}
            operationalHours={operationalHours}
            rawData={v[1]}
            locationId={v[1].id}
          />
        );
      }
    });

    let message_not_found = (
      <div className="mt-5 col-12 text-center">
        <h1 style={{ color: "#888888" }}>Result not found.</h1>
      </div>
    );

    return facilityCard.length < 1 ? message_not_found : facilityCard;
  }

  componentDidMount() {
    this.getFacilities();
    this.getLocations();
  }

  handleChange(e) {
    if (e) {
      return this.getFacilities(e.value);
    }

    return this.getFacilities();
  }

  loaderSpinner() {
    return (
      <div className="col-2 mx-auto mt-5 text-center">
        <ReactLoading type="bubbles" color="#888888" />
      </div>
    );
  }

  getLocations() {
    let url = `/getLocations`;

    this.setState({
      isLoading: true,
    });

    apiCaller
      .get(url)
      .then((res) => {
        this.setState({
          locationLoading: false,
          locations: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          locationLoading: false,
        });
      });
  }

  options() {
    let locations = Object.entries(this.state.locations);
    let locationsArr = [];
    locations.map((value) => {
      let locationDetails = [];
      value[1].map((value2) => {
        return locationDetails.push({
          label: value2.name,
          value: value2.id,
        });
      });

      return locationsArr.push({
        label: value[0],
        options: locationDetails,
      });
    });
    return locationsArr;
  }

  render() {
    const containerStyle = {
      marginTop: 66,
    };

    const heroStyle = {
      background: `linear-gradient(180deg, rgba(57, 29, 40, 0.8) 0%, rgba(55, 5, 29, 0.8) 100%),url('${jumbtronBg}')`,
      minHeight: 331,
      backgroundPosition: "center",
      backgroundSize: "cover",
      color: "#fff",
    };

    const blur = {
      backdropFilter: "blur(5px)",
      zIndex: 99,
    };

    const stylesSelect = {
      container: (base) => ({
        ...base,
        flex: 1,
        textAlign: "left",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          color: "#000",
          cursor: isDisabled ? "not-allowed" : "default",
        };
      },
    };

    if (this.state.isLoading === true && this.state.locationLoading === true) {
      return (
        <>
          <ReactLoading
            type="bubbles"
            style={{
              margin: "0 auto",
              fill: "rgb(136, 136, 136)",
              height: 127,
              width: 127,
            }}
          />
        </>
      );
    }

    return (
      <>
        <Navbar />
        <div className="container-fluid" style={containerStyle}>
          <div className="row" style={heroStyle}>
            <div className="col-12 p-3 p-md-5 text-center" style={blur}>
              <h1 className="mt-5">On-Demand Facility Booking</h1>
              <h5 className="">
                vOffice offers you over 50 available facilities.
              </h5>
              <div className="col-12 col-md-8 col-lg-6 mt-5 mx-auto">
                <div className="input-group">
                  {this.state.user.client_id == 1014180 || this.state.user.client_id == 1018891 || this.state.user.client_id == 1019310 ? (
                    ``
                  ) : (
                    <Select
                      placeholder="Select Location"
                      onChange={(e) => this.handleChange(e)}
                      styles={stylesSelect}
                      options={this.options()}
                      isClearable={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-4">
            {this.state.isLoading
              ? this.loaderSpinner()
              : this.displayFacilityCard()}
          </div>
        </div>
        <FloatingButton />
      </>
    );
  }
}

export default FacilitiesBooking;
