import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import './Home.css';
import bgTerm from "../../components/bg-term.jpg";
import logo from "../../components/logo.svg";

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    componentDidMount() {
        document.title = "vPortal | Terms and Conditions";
    }

    render() {

        return (
            <>  
                <img src={ bgTerm } height="auto" width="100%" alt="bg-term"/>
                    <div className="top-container container row mx-auto mb-3 p-3" style={{ position:'absolute', top:'0', left:'0', right:'0' }}>
                        <div className="col-md-12 p-0">
                            <div className="card">
                                <div className="card-body">
                                    <img src={logo} width="150px" alt="bg-logo-voffice" style={{marginBottom:"0.5rem"}}/>

                                    <div className="d-lg-flex">
                                        <div className="">
                                            <h3 className="m-0">Syarat dan Ketentuan</h3>
                                            <h4 className="opacity-06 m-0" style={{ fontWeight:"200" }}>
                                                <i>
                                                Terms and Conditions
                                                </i>
                                            </h4>
                                        </div>
                                        
                                    </div>
                                    
                                    <hr/>
                                    <ol>
                                        <li style={{padding: '5px'}}>Pembayaran yang sudah dilakukan tidak dapat dikembalikan dengan alasan apapun, hal ini berlaku juga terhadap layanan yang belum diaktivasi atas permintaan Klien.
                                        <label className="opacity-06" style={{ fontWeight:"200" }}>
                                            <i>
                                            Payments that have been made cannot be returned for any reason, this also applies to services that have not been activated at the request of the Client.
                                            </i>
                                        </label>
                                        </li>
                                        <li style={{padding: '5px'}}>Mengenai nama dan detil pada invoice / faktur pajak yang diterbitkan atau diproses oleh vOffice disesuaikan dengan detil informasi yang tertera pada formulir registrasi yang diisi oleh Klien.
                                        <label className="opacity-06" style={{ fontWeight:"200" }}>
                                            <i>
                                            Regarding the name and details on the invoice / tax invoice issued or processed by vOffice, it is adjusted to the detailed information stated on the registration form filled out by the Client. 
                                            </i>
                                        </label>
                                        </li>
                                        <li style={{padding: '5px'}}>Jika ada pertanyaan lebih lanjut harap hubungi cs@voffice.co.id atau telepon di 021-29222999.
                                        <label className="opacity-06" style={{ fontWeight:"200" }}>
                                            <i>
                                            If you have further questions, please contact cs@vOffice.co.id or call 021-29222999. 
                                            </i>
                                        </label>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
}

export default TermsAndConditions;