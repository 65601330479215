import React, { Component } from 'react';
import { loginService } from '../../services/authServices';
import { loginSuccess } from '../../actions/authActions';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import './Login.css';
import { Link } from 'react-router-dom';
import logo from '../../components/logo-white.svg';

class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoading: false
        }
    }

    getGreeting = () => {
        var e = new Date().getHours();

        return e < 12
        ? "Good Morning"
        : e < 18
        ? "Good Afternoon"
        : "Good Evening";
    }

    handleChange = (e) => {
        this.setState({
            [e.target.type]:e.target.value
        })
    }

    handleLogin = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        });
        const doLogin = await loginService(this.state.email, this.state.password);
        if(doLogin.error){
            swal("Oops", doLogin.response.data.message, "error");
            this.setState({
                isLoading: false
            });
            return false;
        }else{
            localStorage.setItem('token', doLogin.data.token);
            localStorage.setItem("userData", JSON.stringify(doLogin.data));
            this.props.accessGranted(doLogin.data.token);
            this.setState({
                isLoading: false
            });
        }
    }

    render(){
        return (
            <div className="container-fluid p-0">
                <div className="col-12 bg-login">
                    <form className="row align-items-center m-h-100" onSubmit={(e) => console.log(this.handleLogin(e))}>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 mx-auto">
                            <div className="p-b-20 text-center">
                            <img alt='DiPandu CRM' src={logo} width="180" />
                            </div>
                            <h5 className="text-center mb-4 white">
                                {this.getGreeting()}, good to see you again
                            </h5>
                            <div className="login-box">
                                <div className="form-row">
                                    <div className="form-group w-100 floating-label">
                                        <label>Email</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={(e) => this.handleChange(e)}
                                        />
                                    </div>
                                    <div className="form-group w-100 floating-label">
                                        <label>Password</label>
                                        <input
                                        type="password"
                                        className="form-control m-b-10"
                                        placeholder="Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={(e) => this.handleChange(e)}
                                        />
                                    </div>
                                    <div className="form-group row w-100 mx-0 mb-0 mt-2">
                                        <div className="col-6 pl-0 my-auto">
                                            <Link to='/forgot' className='color-primary text-underline'>
                                                Forgot Password
                                            </Link>
                                        </div>
                                        <div className="col-6 pr-0 text-right">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-lg"
                                                onSubmit={(e) => this.handleLogin(e)} disabled={this.state.isLoading ? true : false}
                                                >
                                                    {
                                                        this.state.isLoading ? <div className="spinner-border text-secondary" role="status"><span className="sr-only">Loading...</span></div> : 'Sign In'
                                                    }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 text-center mt-4">
                            {/* <Link className='text-underline' to='/register'>Create new account</Link> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

// const dispact

const mapDispatchToProps = (dispatch) => {
    return {
        accessGranted: (token) => { dispatch(loginSuccess(token)); }
    }
}

export default connect(null, mapDispatchToProps)(Home);