import React, { Component } from "react";
import Slider from "react-slick";
// import Sidebar from '../../components/Sidebar';
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";
import Navbar from "../../components/Navbar";
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QuotaCard from "./components/QuotaCard";
import { BiBookmark, BiPhoneIncoming, BiReceipt, BiMapPin, BiMailSend, BiBuildings} from "react-icons/bi";
import { apiCaller } from "../../services/apiCaller";
import IconFb from "../../components/fb_icon.png";
import IconIg from "../../components/ig_icon.png";
import IconLi from "../../components/li_icon.png";
import IconYt from "../../components/yt_icon.png";
import FloatingButton from "../../components/FloatingButton";
import ReactLoading from 'react-loading';
import BannerCard from "./components/BannerCard";
import Axios from "axios";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            meetingRoomQuota: 0,
            privateOfficeQuota: 0,
            workstationQuota: 0,
            eventSpaceQuota: 0,
            badgeCall: 0,
            badgeMail: 0,
            badgeVisitor: 0,
            badgeInvoice: 0,
            banners: {},
            isCoworking: null
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    onScroll = () => {
        this.setState({})
    }

    componentDidMount(){
        document.title = "vPortal | Home";
        this.getDashboardData();
    }



    createShortcutNav = (icon, link, txt, length) => {
        return (
            <Link to={link} className={`text-center p-2`}>
                <div className="icon-size container-icon mb-1 position-relative">
                    {icon} <span className={`badge badge-danger notify-badge font-size-sm ${length <= 0 ? "d-none" : ""}`}>{length}</span>
                </div>
                <span className="font-size-sm font-rg">{txt}</span>
            </Link>
        );
    }

    getDashboardData()
    {
        apiCaller.get(`/getDashboardData`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            this.setState({
              isLoading: false,
              meetingRoomQuota: res.data.client_quotas.meeting_room_quota,
              privateOfficeQuota: res.data.client_quotas.private_office_quota,
              workstationQuota: res.data.client_quotas.workstation_quota,
              eventSpaceQuota: res.data.client_quotas.event_space_quota,
              badgeCall: res.data.calls,
              badgeMail: res.data.mails,
              badgeVisitor: res.data.visitors,
              badgeInvoice: res.data.invoices,
              banners: res.data.banner_data
            });
        })
        .catch(err => {
            console.log(err);
        })
    }

    carouselBanner(){
        const bannersArray = Object.entries(this.state.banners);
        let bannerCard = []

        bannersArray.map(v => {
            return bannerCard.push(
              <BannerCard
                key={v[1].id}
                image_url={v[1].image_url}
                redirect_url={v[1].redirect_url}
                description={v[1].description}
              />
            );
        })
        
        return bannerCard.length < 1 ? null : bannerCard;
    }

    render() {
        var couroselSet = {
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        const client_name = this.userData.first_name;
        const company_name = this.userData.company_name;
        const client_id = this.userData.client_id;
        const banner = this.carouselBanner();

        // console.log(this.userData);
        
        const link = ["https://www.facebook.com/vofficeindonesia/", 
        "https://www.instagram.com/voffice/?hl=id", 
        "https://id.linkedin.com/company/voffice-indonesia",
        "https://www.youtube.com/channel/UC6N5WQ9aOM85yDu9dmprCpA"];

        if (this.state.isLoading) {
            return (
                <>
                    <ReactLoading type="bubbles" style={{
                        margin: "0 auto",
                        fill: "rgb(136, 136, 136)",
                        height: 127,
                        width: 127
                    }} />
                </>
            )
        }

        return (
            <>
                <Navbar />
                <div className=" container-cstm mx-auto">
                    <div className="">
                        <div className="quota-card-container ">
                            <div className="bg-sm-white">
                                    <div className="card-container-m card-container pb-5-cstm pt-5-cstm section-top-hero mb-0">
                                    <div className="row m-0">
                                        <div className="avatar-col p-0">
                                            <Avatar />
                                        </div>
                                        <div className="col-9 p-0 pl-3">
                                            <label className="d-block opacity-07 font-size-rg font-rg mb-0 white">
                                                {company_name} ({client_id})
                                            </label>
                                            <label className="mb-0 font-size-md white">
                                                {client_name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="container-sc-nav d-lg-none">
                                        <div className="short-cut-nav">
                                            {this.createShortcutNav(<BiBookmark />, "/facility-booking", "Booking", )}
                                            {this.createShortcutNav(<BiPhoneIncoming />, "/calls", "Calls", this.state.badgeCall)}
                                            {this.createShortcutNav(<BiMailSend />, "/mails", "Mails", this.state.badgeMail)}
                                            {this.createShortcutNav(<BiReceipt />, "/invoices", "Invoice", this.state.badgeInvoice)}
                                            {this.createShortcutNav(<BiMapPin />, "/visitors", "Visitor", this.state.badgeVisitor)}
                                            {this.createShortcutNav(<BiBuildings />, "/coworking", "Coworking", ) }
                                            {/* {this.createShortcutNav(<BiDollar />, "/reward", "Reward", )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-sc-nav">
                            <div className="short-cut-nav">
                                {this.createShortcutNav(<BiBookmark />, "/facility-booking", "Booking", )}
                                {this.createShortcutNav(<BiPhoneIncoming />, "/calls", "Calls", this.state.badgeCall)}
                                {this.createShortcutNav(<BiMailSend />, "/mails", "Mails", this.state.badgeMail)}
                                {this.createShortcutNav(<BiReceipt />, "/invoices", "Invoice", this.state.badgeInvoice)}
                                {this.createShortcutNav(<BiMapPin />, "/visitors", "Visitor", this.state.badgeVisitor)}
                                {this.createShortcutNav(<BiBuildings />, "/coworking", "Coworking", )}
                                {/* {this.createShortcutNav(<BiDollar />, "/reward", "Reward", )} */}
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="card-container w-100 card-container-m pt-4-5rem">
                                <label className="d-block font-size-rg mb-0 pt-4-cstm">
                                Credit Balance
                                </label>
                                <span className="d-block mb-16 font-size-sm opacity-07">
                                Available quota of your account
                                </span>
                                <div className="row m-0">
                                    <QuotaCard
                                        quotaName="Meeting Room"
                                        quotaValue={this.state.meetingRoomQuota}
                                        quotaBg={"quota-meeting"}
                                        quotaClass={"quota-active"}
                                    />
                                    <QuotaCard
                                        quotaName="Private Office"
                                        quotaValue={this.state.privateOfficeQuota}
                                        quotaBg={"quota-private"}
                                        quotaClass={"quota-orange"}
                                    />
                                    <QuotaCard
                                        quotaName="Event Space"
                                        quotaValue={this.state.eventSpaceQuota}
                                        quotaBg={"quota-eventspace"}
                                        quotaClass={"quota-blue"}
                                    />
                                    <QuotaCard
                                        quotaName="Workstation"
                                        quotaValue={this.state.workstationQuota}
                                        quotaBg={"quota-workstation"}
                                        quotaClass={"quota-green"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-container card-container row card-container-m" style={banner === null ? {display: 'none'} : {display: 'block'}}>
                        <div className="col-md-12 p-0">
                            <label className="font-size-rg pt-1 mb-0">
                            What's New?
                            </label>
                            <span className="d-block mb-16 font-size-sm opacity-07">
                            Keep updated on vOffice's promotions
                            </span>
                        </div>
                        <div className="col-md-12 p-0">
                            <Slider {...couroselSet}>
                                {this.carouselBanner()}
                            </Slider>
                        </div>
                    </div>
                    <div className="table-container card-container row card-container-m">
                        <div className="col-md-12 p-0">
                            <label className="font-size-rg pt-1 mb-16">
                                Connect With Us
                            </label>
                            {/* <span className="d-block mb-16 font-size-sm opacity-07">
                                Keep updated on vOffice's promotions
                            </span> */}
                        </div>
                        <div className="col p-0 connect-crd">
                            <a href={link[0]} target="_blank" rel="noopener noreferrer">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img alt="Social Account" src={IconFb} width="auto" height="24px" />
                                        <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col p-0 connect-crd">
                            <a href={link[1]} target="_blank" rel="noopener noreferrer">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img alt="Social Account" src={IconIg} width="auto" height="24px"/>
                                        <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">@voffice</label>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col p-0 connect-crd">
                            <a href={link[2]} target="_blank" rel="noopener noreferrer">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img alt="Social Account" src={IconLi} width="auto" height="24px"/> 
                                        <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col p-0 connect-crd">
                            <a href={link[3]} target="_blank" rel="noopener noreferrer">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img alt="Social Account" src={IconYt} width="auto" height="24px"/> 
                                        <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;