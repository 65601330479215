import React, { Component } from 'react';
import { IoIosClock, IoIosPeople, IoIosPin, IoIosSnow } from 'react-icons/io';
import { FiCoffee, FiWifi,FiMonitor } from "react-icons/fi";
import { RiProjector2Line } from "react-icons/ri";
import './FacilityCard.css';
import { Link } from 'react-router-dom';

class FacilityCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    projectorCondition(){
        const benefitCircle = {
            width: 35,
            height: 35,
            color: "rgb(255 255 255)",
            background: "rgb(45 17 39)",
            borderRadius: 100,
            verticalAlign: "middle",
            horizontalAlign: "middle",
            textAlign: "center",
            lineHeight: "30px",
            fontSize: "1rem",
            display: "block",
            margin: "0 auto"
        }
        let projector_status = this.props.rawData.projector_tv
        if (this.props.facilityType !== 3 && this.props.facilityType !== 4) {
            if(projector_status == 1){
                return (
                  <div className="col p-0 mx-auto text-center mt-2">
                    <div style={benefitCircle} className="mb-1">
                      <RiProjector2Line style={{}} />
                    </div>
          
                    <span
                      style={{
                        fontSize: 11,
                        textAlign: "center",
                        width: "100%",
                        display: "block",
                      }}
                    >
                      Projector 
                    </span>
                    
                  </div>
                );
              }else if(projector_status == 2){
                return (
                        <div className="col p-0 mx-auto text-center mt-2">
                          <div style={benefitCircle} className="mb-1">
                            <FiMonitor style={{}} />
                          </div>
        
                          <span
                            style={{
                              fontSize: 11,
                              textAlign: "center",
                              width: "100%",
                              display: "block",
                            }}
                          >
                            Smart TV
                          </span>
                        </div>
                      );
              }else if(projector_status == 3){
                return (
                        <div className="col p-0 mx-auto text-center mt-2">
                          <div style={benefitCircle} className="mb-1">
                            <FiMonitor style={{}} />
                          </div>
        
                          <span
                            style={{
                              fontSize: 11,
                              textAlign: "center",
                              width: "100%",
                              display: "block",
                            }}
                          >
                           Projector & Smart TV
                          </span>
                        </div>
                      );
              }else {
                return "";
              }
        }
        else
        {
            return '';
        }
    }
    facilityNote(){
        if(this.props.locationId === 99999){
            return    <div className="ml-2 mt-4">
            <span style={{
                            fontSize: 14,
                            textAlign: "center",
                            width: "100%",
                            display: "block",
                            color:"red",
                            fontWeight:"bold"
                        }}>
                Note: Projector Currently Unusable*
            </span>
        </div>
        } else
        {
            return '';
        }
    }
    noteBali(){
        if(this.props.locationId === 1816 || this.props.locationId === 1817){
            return <div className="ml-1 mt-4">
            <span style={{
                            fontSize: 11,
                            textAlign: "justify",
                            width: "100%",
                            display: "block",
                            color:"black",
                            fontWeight:"100"
                        }}>
               For Meeting Room Reservation, kindly contact our Customer Team at 021-29222999 or cs@voffice.co.id or WhatsApp +62 812-1209-7353
            </span>
        </div> 
         
        }
    }
    render() {
        let image = `url(${require('../../../assets/images/no-image.png') })`;

        if (this.props.facilityImage)
        {
            image = `url('${this.props.facilityImage}')`;
        }

        const imageBox = {
            minHeight: 212,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: image,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }

        const locationPin = {
            marginTop: -4,
            marginLeft: -5,
            marginRight: -2
        }

        const benefitCircle = {
            width: 35,
            height: 35,
            color: "rgb(255 255 255)",
            background: "rgb(45 17 39)",
            borderRadius: 100,
            verticalAlign: "middle",
            horizontalAlign: "middle",
            textAlign: "center",
            lineHeight: "30px",
            fontSize: "1rem",
            display: "block",
            margin: "0 auto"
        }

        return (
            <div className="col-12 col-md-6 col-lg-4 mx-auto px-1 px-md-2 mb-4">
                <div className="card facility-card mb-0 mx-0" style={{ 
                    // minHeight: 667,
                    border: "none"
                }}>
                    <div className="card-img-top mb-2" style={imageBox} />
                    <div className="card-body">
                        <h5 className="mb-1 font-sb color-primary">{this.props.facilityName}</h5>
                        <label className="card-title font-size-sm font-sb mt-1 mb-0">
                            <IoIosPin style={locationPin} />
                            <span className="ml-2">{this.props.locationName}</span>
                        </label>
                        <p className="card-text font-size-sm opacity-06 mb-1">{this.props.address}</p>
                        <div className="row mt-2">
                            <div className="col-12">
                                <IoIosPeople style={{ 
                                    fontSize: 20,
                                    marginTop: -5,
                                    marginRight: 5
                                }} /> 
                                <span style={{ 
                                    fontSize: "0.8rem"
                                }}>{this.props.minPax} - {this.props.maxPax} Guest(s)</span>
                            </div>
                            <div className="col-12 mb-2">
                                <IoIosClock style={{ 
                                    fontSize: 20,
                                    marginTop: -5,
                                    marginRight: 5
                                }} /> 
                                <span style={{ 
                                    fontSize: "0.8rem"
                                }}>{this.props.operationalHours}</span>
                            </div>
                            <div className="col p-0 mx-auto text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <FiCoffee />
                                </div>
                                <span style={{ 
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>Coffee</span>
                            </div>
                            <div className="col p-0 mx-auto text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <FiWifi style={{ 
                                    }} />
                                </div>
                                <span style={{ 
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>Wi-Fi</span>
                            </div>
                            <div className="col p-0 mx-auto text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <IoIosSnow style={{ 
                                    }} />
                                </div>
                                <span style={{ 
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>AC</span>
                            </div>
                            {this.facilityNote()}
                            { this.projectorCondition() }

                        </div>
                            {this.noteBali()}
                    </div>
                            {this.props.locationId === 1816 || this.props.locationId === 1817?"": <Link className="btn btn-primary-sec font-size-rg m-2" to={`/facility-booking/${this.props.rawData.id}`}>Book Now</Link>}
                </div>
            </div>
        );
    }
}

export default FacilityCard;