import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { apiCaller } from "../../services/apiCaller";
import * as moment from "moment";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";
import * as numeral from "numeral";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceLoading: true,
            invoiceData: {},
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    getClientInvoice() {
        apiCaller.get(`/getClientInvoices/${this.userData.client_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                this.setState({
                    invoiceLoading: false,
                    invoiceData: res.data.data
                })
            })
    }

    showClientInvoiceTable() {
        if (!this.state.invoiceLoading) {
            return this.renderTableData();
        }
    }

    viewInvoices(status, invoiceId)
    {
        if (status === 'Paid')
        {
            window.open("https://vox.myvios.cloud/invoice/receipt/pdf/" + invoiceId)
        }
        else if (status === 'Unpaid')
        {
            window.open("https://vox.myvios.cloud/invoice/proforma/pdf/" + invoiceId)
        }
    }

    componentDidMount() {
        document.title = "vPortal | Invoices";
        this.getClientInvoice();
    }

    renderTableData() {
        const tableData = this.state.invoiceData;
        let result = [];


        for (let i = 0; i < tableData.length; i++) {

            let amount_due = tableData[i].amount_due;

            if (tableData[i].is_custom_invoice)
            {
                amount_due = numeral(amount_due).format('0,0')
            }

            result.push(
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <div className="row m-0">
                                <div className="col-container-6">
                                    <span className="d-block font-size-sm opacity-06">Invoice ID</span>
                                    <label className="font-size-rg font-sb color-primary">
                                    {tableData[i].invoice_number}
                                    </label>
                                </div>
                                <div className="col-container-6">
                                    <div className="badge badge-pill badge-primary pt-2 font-size-rg float-right">
                                    {tableData[i].status}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 border-top">
                                <div className="col-md-3 p-0">
                                    <label className="font-size-rg font-sb mb-0 d-block">
                                    {tableData[i].company_name}
                                    </label>
                                    <div className="row m-0 mb-3">
                                        <FiMapPin className="font-size-rg font-sb d-flex" />
                                        <label className="font-size-sm font-sb mb-0 d-block ml-2">
                                            {tableData[i].location_name}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-flex font-size-sm opacity-06">Amount Due</span>
                                    <label className="font-size-rg font-sb">
                                        {amount_due}
                                    </label>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-flex font-size-sm opacity-06">Due Date</span>
                                    <label className="font-size-rg font-sb">
                                        { moment(tableData[i].date_due).format("D MMM YYYY")}
                                    </label>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-block font-size-sm opacity-06">Due Generated</span>
                                    <label className="font-size-rg font-sb">
                                        {moment(tableData[i].date_generated).format("D MMM YYYY")}
                                    </label>
                                </div>
                            </div>
                            <div className="border-top">
                                <a href=
                                {tableData[i].status === "Paid"? `https://vox.myvios.cloud/invoice/receipt/pdf/${tableData[i].id}`:`https://vox.myvios.cloud/invoice/proforma/pdf/${tableData[i].id}`} target = "_blank" rel="noopener noreferrer" download>
                                <button className="btn btn-primary mt-3 font-size-rg">View Invoice</button>
                                </a>
                                
                               <a href={`https://pay.voffice.co.id/?invoice_id=${tableData[i].id}`} target="_blank" rel="noopener noreferrer"> <button className={"btn btn-primary-sec font-size-rg ml-2 mt-3 " + (tableData[i].status !== "Unpaid" ? "d-none" : "")} >{tableData[i].status === "Unpaid" ? "Pay Now" : "Detail"}</button></a>
                                {/* <Link className={"btn btn-primary-sec font-size-rg ml-2 mt-3 " + (tableData[i].status !== "Unpaid" ? "d-none" : "")} to={`/invoice/details/${tableData[i].id}`}>{tableData[i].status === "Unpaid" ? "Pay Now" : "Detail"}</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }

        if (tableData.length < 1) {
            return <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <center>
                                <h3>No Data Available</h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        }

        return result;
    }

    loaderSpinner(){
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    render() {
        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            <label className="font-size-rg mb-16 pt-1 color-primary"><Link to="/dashboard">< IoIosArrowBack className="mb-1" /> Back</Link></label>
                        <br />
                        <label className="font-size-rg mb-16 pt-1">Last invoice(s)</label>
                        {this.state.invoiceLoading ? this.loaderSpinner() : this.renderTableData()}
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
