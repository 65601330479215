import React, { Component } from 'react';
import { IoIosClock, IoIosPeople, IoIosPin, IoIosSnow } from "react-icons/io";
import { FiCoffee, FiWifi, FiMonitor } from "react-icons/fi";
import { RiProjector2Line } from "react-icons/ri";
import NumberFormat from "react-number-format";

class TabFacilityInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facility: this.props.data,
        }
    }

    projectorCondition(){
        let projector_status = this.props.data.projector_tv

        const benefitCircle = {
            width: 35,
            height: 35,
            color: "rgb(255, 255, 255)",
            background: "rgb(45, 17, 39)",
            borderRadius: 100,
            verticalAlign: "middle",
            horizontalAlign: "middle",
            textAlign: "center",
            lineHeight: "30px",
            fontSize: "1rem",
            display: "block",
            margin: "0 auto"
        }
        
        if (this.props.data.facility_type !== 3 && this.props.data.facility_type !== 4) {
            if(projector_status == 1){
                return (
                    <div className="px-2 text-center mt-2">
                    <div style={benefitCircle} className="mb-1">
                        <RiProjector2Line style={{
                        }} />
                    </div>
                    <span style={{
                        fontSize: 11,
                        textAlign: "center",
                        width: "100%",
                        display: "block"
                    }}>Projector</span>
                </div>
                );
              }else if(projector_status == 2){
                return (
                    <div className="px-2 text-center mt-2">
                    <div style={benefitCircle} className="mb-1">
                        <FiMonitor style={{
                        }} />
                    </div>
                    <span style={{
                        fontSize: 11,
                        textAlign: "center",
                        width: "100%",
                        display: "block"
                    }}>Smart TV</span>
                </div>
                      );
              }else if(projector_status == 3){
                return (
                    <div className="px-2 text-center mt-2">
                    <div style={benefitCircle} className="mb-1">
                        <RiProjector2Line style={{
                        }} />
                    </div>
                    <span style={{
                        fontSize: 11,
                        textAlign: "center",
                        width: "100%",
                        display: "block"
                    }}>Projector & Smart TV</span>
                </div>
                      );
              }else {
                return "";
              }
        }
        else
        {
            return '';
        }
    }

    render() { 
        const locationPin = {
          marginTop: -5,
          marginLeft: -5,
          marginRight: -2,
        };

        const benefitCircle = {
            width: 35,
            height: 35,
            color: "rgb(255, 255, 255)",
            background: "rgb(45, 17, 39)",
            borderRadius: 100,
            verticalAlign: "middle",
            horizontalAlign: "middle",
            textAlign: "center",
            lineHeight: "30px",
            fontSize: "1rem",
            display: "block",
            margin: "0 auto"
        }

        const facility = this.state.facility;
        const operationalHours = facility.start_time.toString().slice(0, -2)+":00 - "+facility.end_time.toString().slice(0, -2)+":00";

        return ( 
            <>
                <div className="tab-pane fade" id="line-profile" role="tabpanel"
                        aria-labelledby="profile-tab">
                    <div className="card-body p-0">
                        <h5 className="mb-1 font-sb color-primary">{facility.facility_name}</h5>
                        <label className="card-title font-sb mt-1 mb-0 d-block"><IoIosPin style={locationPin} /> {facility.location_name}</label>
                        <p className="card-text font-size-sm opacity-06 mb-2">{facility.address}</p>
                        <div className="row mb-1">
                            <div className="col-12">
                                <IoIosPeople style={{ 
                                    fontSize: 20,
                                    marginTop: -5,
                                    marginRight: 5
                                }} /> 
                                <span style={{ 
                                    fontSize: "0.8rem"
                                }}>{facility.min_pax} - {facility.max_pax} Guest(s)</span>
                            </div>
                            <div className="col-12 mb-2">
                                <IoIosClock style={{ 
                                    fontSize: 20,
                                    marginTop: -5,
                                    marginRight: 5
                                }} /> 
                                <span style={{ 
                                    fontSize: "0.8rem"
                                }}>{operationalHours}</span>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="px-2 text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <FiCoffee style={{
                                    }} />
                                </div>
                                <span style={{
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>Coffee</span>
                            </div>
                            <div className="px-2 text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <FiWifi style={{
                                    }} />
                                </div>
                                <span style={{
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>Wi-Fi</span>
                            </div>
                            <div className="px-2 text-center mt-2">
                                <div style={benefitCircle} className="mb-1">
                                    <IoIosSnow style={{
                                    }} />
                                </div>
                                <span style={{
                                    fontSize: 11,
                                    textAlign: "center",
                                    width: "100%",
                                    display: "block"
                                }}>AC</span>
                            </div>
                            {this.projectorCondition()}
                        </div>
                        <div className="alert alert-danger mt-4 mb-0" role="alert">
                            <NumberFormat className="mb-0 font-sb color-secondary" value={facility.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                            <span className="font-size-sm font-rg opacity-06 ml-2">Overtime Charge *</span>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default TabFacilityInfo;