import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import './Visitors.css';
import { apiCaller } from "../../services/apiCaller";
import * as moment from "moment";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitorLoading: true,
            visitorData: {},
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    getClientVisitor() {
        apiCaller.get(`/getClientVisitors/${this.userData.client_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                this.setState({
                    visitorLoading: false,
                    visitorData: res.data.data
                })
            })
    }

    showClientVisitorTable() {
        if (!this.state.visitorLoading) {
            return this.renderTableData();
        }
    }

    componentDidMount() {
        document.title = "vPortal | Visitors";
        this.getClientVisitor();
    }

    renderTableData() {
        const tableData = this.state.visitorData;
        let result = [];

        for (let i = 0; i < tableData.length; i++) {
            result.push(
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-light mb-3">
                            {/* <div class="card-header">Header</div> */}
                            <div className="card-body">
                                <div className="row m-0">
                                    <div className="col-md-4 p-0">
                                        <span className="d-block font-size-sm opacity-06">Visitor</span>
                                        <label className="font-size-rg font-sb color-primary">
                                        {tableData[i].visitor_name}
                                        </label>
                                    </div>
                                    <div className="col-md-4 p-0">
                                        <span className="d-block font-size-sm opacity-06">PIC to </span>
                                        <label className="font-size-rg font-sb color-primary">
                                        {tableData[i].pic_name}
                                        </label>
                                    </div>
                                    <div className="col-md-4 p-0 mb-2">
                                        <span className="d-block font-size-sm opacity-06">Already Appointment</span>
                                        <div className="badge badge-pill badge-success pt-2 font-size-rg">
                                        {tableData[i].appointment_ready}
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 border-top">
                                    <div className="col-md-4 p-0">
                                        <span className="d-block font-size-sm opacity-06">Location </span>
                                        <label className="font-size-rg font-sb">
                                        {tableData[i].location_name}
                                        </label>
                                    </div>
                                    <div className="col-md-4 p-0">
                                        <span className="d-block font-size-sm opacity-06">Check In At </span>
                                        <label className="font-size-rg font-sb">
                                        {moment(tableData[i].check_in_at).format("D MMM YYYY HH:mm")}
                                        </label>
                                    </div>
                                    <div className="col-md-4 p-0">
                                        <span className="d-block font-size-sm opacity-06">Oprator </span>
                                        <label className="font-size-rg font-sb">
                                        {tableData[i].o_name}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (tableData.length < 1) {
            return <div className="row">
                <div className="col-12">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <center>
                                <h3>No Data Available</h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        }

        return result;
    }

    loaderSpinner(){
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    render() {

        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            <label className="font-size-rg mb-16 pt-1 color-primary"><Link to="/dashboard">< IoIosArrowBack className="mb-1" /> Back</Link></label>
                            <br />
                            <label className="font-size-rg mb-16 pt-1">Last visitor(s)</label>
                            {this.state.visitorLoading ? this.loaderSpinner() : this.renderTableData()}
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
