import React, { Component } from 'react';
import { IoIosPin } from 'react-icons/io';
import * as moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiOutlineShare } from 'react-icons/hi';
import { apiCaller } from '../../../services/apiCaller';
import swal from "sweetalert";


class CardData extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: '',
        }
        this.bookingData = this.props.data;
    }

    displayTimeSlots(){
        const slot = Object.values(this.bookingData.time_slots);
        
        return slot.map((v, k) => {
            return <div key={k} className="badge badge-primary mr-1 mb-1">{v}</div>;
        })                            
    }
    
    cancelBooking(bookingId)
    {
        return swal({
            title: "Are you sure?",
            text:
                `Are you sure want to cancel this booking?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmBooking) => {
            if (confirmBooking) {
                
                let params = {
                    booking_id: bookingId
                }

                apiCaller.post("/cancelBooking", params)
                .then((res) => {
                    return swal("Success", "Your booking has been cancelled.", "success").then(() => {
                        return document.location.reload();
                    });
                })
                .catch(err => {
                    return swal("Oops", err.response.data.message, "error");
                });
            }
        });
    }

    render() { 
        const webUrl = "https://portal.voffice.co.id";
        const bookingId = this.bookingData.booking_id;
        
        return (  
            <div className="row">
                <div className="col-12">
                    <div className="card bg-light mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <label className="text-dark font-weight-bold">ID : {this.bookingData.booking_id}</label>
                                    <label style={{marginLeft: '10px'}} className={`text-light badge ` + ((this.bookingData.book_status === 0) ? 'badge-success' : 'badge-danger') + ` font-weight-bold`}>{((this.bookingData.book_status === 0) ? 'Active' : 'Cancelled')}</label>
                                </div>
                                <div className="col-6 text-right">
                                    <label className="text-light badge badge-success font-weight-bold">{this.bookingData.facility_category}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="w-100 mb-0 opacity-06 font-size-sm">Booking Date :</label>
                                    <label className="w-100 mb-0">{moment(this.bookingData.booking_date).format("D MMM YYYY")}</label>
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="w-100 mb-0 opacity-06 font-size-sm">Booked Slots :</label>
                                    {this.displayTimeSlots()}
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="w-100 mb-0 opacity-06 font-size-sm">Location :</label>
                                    <label className="w-100 mb-0"><IoIosPin />{this.bookingData.location_name}</label>
                                    <p className="font-size-sm" dangerouslySetInnerHTML={{  __html: this.bookingData.address }}></p>
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <label className="w-100 mb-0 opacity-06 font-size-sm">Facility :</label>
                                    <label className="w-100 mb-0">{this.bookingData.facility_name}</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-5">
                                    <span className="opacity-06 font-size-sm">Created at : </span>
                                    <label className="mb-0">{moment(this.bookingData.created_at).format("D MMM YYYY HH:mm")}</label>
                                </div>

                                <div className="col-7 text-right">
                                    {
                                        ((this.bookingData.book_status === 0) ? <button type="button" onClick={() => this.cancelBooking(bookingId)} className="mb-0 mt-2 mr-2 font-weight-bold btn btn-outline-primary">
                                            <label style={{ cursor: 'pointer' }} className="mb-0"><span className=" ml-1">Cancel</span></label>
                                        </button> : '')
                                    }
                                    <CopyToClipboard
                                        className="text-light mb-0 mt-2 font-weight-bold btn btn-primary"
                                        text={`${webUrl}/booking-share/${bookingId}`}
                                        onCopy={(e) => {
                                            return toast.info("Copied!", {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        }}>
                                        <label className="mb-0"><HiOutlineShare/> <span className="d-none d-md-inline-block">Copy Link</span></label>
                                    </CopyToClipboard>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardData;