import React, { Component } from 'react';

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatarText: ''
        }
    }

    componentDidMount(){
        this.setState({
            avatarText: this.createAvatarText(JSON.parse(localStorage.getItem("userData")))
        })
    }


    createAvatarText = (userData) => {
        const firstChar = userData.first_name.substring(0, 1);
        const lastChar = userData.last_name ? userData.last_name.substring(0, 1) : "";

        return firstChar+lastChar;
    }

    render() { 
        const avatarImg = {
          width: 65,
          height: 65,
          background: "#ffffff",
          display: "block",
          // margin: "0 auto",
          verticalAlign: "middle",
          horizontalAlign: "middle",
          textAlign: "center",
          lineHeight: "65px",
          fontSize: "2rem",
          color: "#9a2b59",
          borderRadius: "100%",
        };

        return <div style={avatarImg}>{this.state.avatarText}</div>;
    }
}

export default Avatar;