import { cloneElement, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { apiCaller } from "../services/apiCaller";
import swal from "sweetalert";

const { oneOfType, arrayOf, node, func, string } = PropTypes

export default class SnapMidtrans extends PureComponent {
    state = {
        children: null,
        token: '',
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps.token !== prevState.token
        ? { token: nextProps.token }
        : null
    }

    constructor(props) {
        super(props)

        // bind react-midtrans method
        this.mergeWithChildren = this.mergeWithChildren.bind(this)
        // backup currentview
        this.currentViewport = document
        .getElementsByTagName('meta')
        .hasOwnProperty('viewport')
        ? document.getElementsByTagName('meta').viewport
        : ''
        // create element for script
        this.snapScript = document.createElement('script')

        // checking environment mode
        // this.snapScript.src =
        // ENV === 'production'
        //     ? 'https://app.midtrans.com/snap/snap.js'
        //     : 'https://app.sandbox.midtrans.com/snap/snap.js'

        this.snapScript.src = 'https://app.midtrans.com/snap/snap.js';

        this.snapScript.type = 'text/javascript'
        this.snapScript.onload = this.onLoad.bind(this)
        this.snapScript.dataset.clientKey = props.clientKey
    }

    onLoad(e) {
        if ('snap' in window) {
        const { snap } = window
        this.setState({ snap })
        }
    }

    componentDidMount() {
        document.head.appendChild(this.snapScript)
        this.mergeWithChildren(this.props.children, this.props.invoiceData)
    }

    mergeWithChildren(children, invoiceData) {
        children = cloneElement(
        children,
        // Assign new Props
        {
            onClick: () => {
            // If Children have a onClick
            children.onClick && children.onClick()
            if (this.state.token && this.state.token !== '') {
                this.state.snap.pay(
                this.state.token, {
                    /** @todo options **/
                    onSuccess: function (result) {
                        const paramsAPI = {
                            invoice_id: invoiceData.id,
                            amount_due: parseInt(result.gross_amount),
                            payload: JSON.stringify(result),
                            status: result.transaction_status,
                            transaction_id: result.order_id,
                        }

                        apiCaller.post(`/midtrans/payment/transaction`, paramsAPI, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(() => {
                            return document.location.replace("/payment/success/" + result.order_id);
                        })
                        .catch(err => {
                            return swal("Oops", err.response.data.message, "error");
                        });

                    },
                    onPending: function (result) {
                        const paramsAPI = {
                            invoice_id: invoiceData.id,
                            amount_due: parseInt(result.gross_amount),
                            payload: JSON.stringify(result),
                            status: result.transaction_status,
                            transaction_id: result.order_id,
                        }

                        apiCaller.post(`/midtrans/payment/transaction`, paramsAPI, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(() => {
                            return document.location.replace("/payment/pending/" + result.order_id);
                        })
                        .catch(err => {
                            return swal("Oops", err.response.data.message, "error");
                        });
                    },
                    onError: function (result) {
                        const paramsAPI = {
                            invoice_id: invoiceData.id,
                            amount_due: parseInt(result.gross_amount),
                            payload: JSON.stringify(result),
                            status: result.transaction_status,
                            transaction_id: result.order_id,
                        };

                        apiCaller.post(`/midtrans/payment/transaction`, paramsAPI, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(res => {
                            return document.location.replace("/payment/error/" + result.order_id);
                        })
                        .catch(err => {
                            return swal("Oops", err.response.data.message, "error");
                        });
                    },
                }                
                )
            }
            this.props.onClick && this.props.onClick()
            },
        },
        )

        this.setState({
        children,
        })
    }

    render() {
        return this.state.children
    }
}

/**
 * @module SnapMidtrans
 * @param {Object} props
 * @property {ReactElement} children - required
 * @property {String} token
 * @todo 4 callback
 * @property {Function} onSuccess
 * @property {Function} onError
 * @property {Function} onPending
 * @property {Function} onClose
 */
SnapMidtrans.propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired,
    clientKey: string.isRequired,
    token: string,

    /* @see @link {https://snap-docs.midtrans.com/#snap-js|Midtrans API 4 Callback} */
    onSuccess: func,
    onPending: func,
    onError: func,
    onClose: func,

    /* Callback Or Custom onClick */
    onClick: func,
}