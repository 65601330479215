import React, { Component } from 'react';
import logo from "../../components/logo.svg";
import { IoIosPin } from "react-icons/io";
import { apiCaller } from '../../services/apiCaller';
import ReactLoading from 'react-loading';
import * as moment from "moment";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import SocialMediaButton from "../SocialMedia/SocialMediaButton";
import { FiMapPin } from 'react-icons/fi';

class BookingShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            bookingData: {},
            lat: "",
            lng: ""
        }
    }

    componentDidMount() {
        document.title = "vPortal | Bookings";
        this.getBookingDetails();
    }

    getBookingDetails() {
        const { match: { params } } = this.props;
        apiCaller.get(`/bookingDetails/${params.bookingId}`)
            .then(res => {
                let coordinates = res.data.location_coordinates;
                let lat = '';
                let lng = '';

                if (coordinates !== '-')
                {
                    let coordinatesArr = String(coordinates).split(' ');
                    lat = coordinatesArr[0];
                    lng = coordinatesArr[1];
                }

                this.setState({
                    isLoading: false,
                    bookingData: res.data,
                    lat: lat,
                    lng: lng,
                });
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
            });
    }

    displayTimeSlots() {
        const slot = Object.values(this.state.bookingData.time);

        return slot.map((v, k) => {
            return <div key={k} className="badge badge-primary mr-1 mb-1">{v}</div>;
        })
    }

    showMap = () => {
        if (this.state.lat) {
            return (<Map center={[this.state.lat, this.state.lng]} zoom={23} minZoom={5} className="location-map">
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[this.state.lat, this.state.lng]} ref={this.openPopup}>
                    <Popup>
                        <p className="font-size-sm" dangerouslySetInnerHTML={{ __html: this.state.bookingData.location_address }}></p>
                    </Popup>
                </Marker>
            </Map>)
        }
    }

    openPopup(marker) {
        if (marker && marker.leafletElement) {
            window.setTimeout(() => {
                marker.leafletElement.openPopup()
            })
        }
    }

    navigateMe = () => {
        window.open('http://maps.google.com/maps/place/'+this.state.lat+','+ this.state.lng);
    }

    render() { 

        if (this.state.isLoading) {
            return (
                <>
                    <ReactLoading type="bubbles" style={{
                        margin: "0 auto",
                        fill: "rgb(136, 136, 136)",
                        height: 127,
                        width: 127
                    }} />
                </>
            )
        }

        return (
          <div className="row container mx-auto">
            <div className="col-lg-12 col-md-12 text-center">
              <img alt="vOffice Logo" src={logo} width="257px" className="mt-5 mb-4" />
              <h3>BOOKING DETAIL</h3>
            </div>
            <div className="col-lg-12 col-md-12 mt-4">
              <div className="card card-container-m mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <label className="text-dark font-weight-bold">
                        ID : {this.state.bookingData.booking_id}
                      </label>
                    </div>
                    <div className="col-6 text-right">
                      <label className="text-light badge badge-success font-weight-bold">
                        {this.state.bookingData.facility_category}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                      <label className="w-100 mb-0 opacity-06 font-size-sm">
                        Person in Charge :
                      </label>
                      <label className="w-100 mb-0">
                        {this.state.bookingData.client}
                      </label>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      <label className="w-100 mb-0 opacity-06 font-size-sm">
                        Booking Date :
                      </label>
                      <label className="w-100 mb-0">
                        {moment(this.state.bookingData.booking_date).format(
                          "D MMM YYYY"
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      <label className="w-100 mb-0 opacity-06 font-size-sm">
                        Booked Slots :
                      </label>
                      {this.displayTimeSlots()}
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      <label className="w-100 mb-0 opacity-06 font-size-sm">
                        Facility :
                      </label>
                      <label className="w-100 mb-0">
                        {this.state.bookingData.facility_name}
                      </label>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      <label className="w-100 mb-0 opacity-06 font-size-sm">
                        Location :
                      </label>
                      <label className="w-100 mb-0">
                        <IoIosPin />
                        {this.state.bookingData.location_name}
                      </label>
                      <p
                        className="font-size-sm"
                        dangerouslySetInnerHTML={{
                          __html: this.state.bookingData.location_address,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-9 col-md-6">
                      <label className="mb-0 font-size-sm">
                        <span className="opacity-06" >Created at : </span>
                        <label className="text-dark mb-0">
                          {moment(this.state.bookingData.created_at).format(
                            "D MMM YYYY HH:mm"
                          )}
                        </label>
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-6 navigate-me">
                      <span
                        onClick={this.navigateMe}
                        className="text-light font-weight-bold btn btn-primary"
                      >
                        <FiMapPin /> Navigate Me
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-3">
              <div className=" card-container-m">{this.showMap()}</div>
            </div>
            <div className="col-lg-12 col-md-12">
              <SocialMediaButton />
            </div>
          </div>
        );
    }
}
 
export default BookingShare;