import React, { Component } from 'react';
import "./FacilityBookTabs.css";

class TabGuidlinesFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
            <div className="bg-white tab-pane fade" id="line-contact" role="tabpanel"
                    aria-labelledby="contact-tab">
                <h5 className="text-center m-b-2 title-font">GUIDELINES</h5>
                <p className="mt-4">
                    Clients are responsible for leaving meeting and conference rooms and event spaces in good order after use including:
                </p>
                <div className="mt-4 guide-lines mb-4">
                    <ul>
                        <li className=" font-rg  "> Please refrain from making any excessive loud noises that can be disturbing to other clients</li>
                        <li className="pt-3 font-rg">Kindly assist to maintain the cleanliness of the meeting room</li>
                        <li className="pt-3 font-rg"> Kindly informed our Customer Service Team (Call Center Officer or Executive Assistant) if you require additional hour</li>
                        <li className="pt-3 font-rg">A fixed penalty of <strong>
                        IDR 10.000.000 </strong>  will be imposed to the participant(s) for smoking inside the building
                            </li>
                        <li className="pt-3 font-rg">Kindly Maximum room capacity rules apply to attendees</li>
                        <li className="pt-3 font-rg">Monthly free room quota will only be returned if cancellation or reschedule is proceed or requested during working day but 24 hours before reserved room is used</li>
                        <li className="pt-3 font-rg">Set up and special arrangements of chairs need shall be approved by vOffice team</li>
                        <li className="pt-3 font-rg">Damage caused to any facility or equipment will be charged to the company or person reserving the room</li>
                        <li className="pt-3 font-rg">Room monthly quota is not accumulated to the next month.</li>
                    
                    </ul>
                </div>
                <hr/>  
                <p className="mt-4">
                    Cancelation Policy:
                </p>
                <div className="mt-4 guide-lines mb-5">
                    <ul>
                        <li className="font-rg"> Deducted room quota will only be returned if cancellation or reschedule is proceed or requested during working day but <strong>24 hours</strong> before reserved room is used.
                                                                                                             </li>
                    </ul>
                </div>
                <hr/>
                <br/>
                <h5 className="text-center title-font">FAQs</h5>
                <div className="m-b-30 mt-4">
                    <div className="accordion " id="accordionExample">
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading1">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block" data-toggle="collapse"
                                    data-target="#collapse1"
                                    aria-controls="collapse1">
                                        Are there Internet Connection in the space?
                                    </a>
                                </h5>
                            </div>

                            <div id="collapse1" className="collapse" aria-labelledby="heading1"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    Wireless Internet Connection for all participants is included in the charges.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading2">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse2" aria-expanded="false"
                                    aria-controls="collapse2">
                                        I need a more superior Internet Connection for my video conference call. 
                                        Can you provide me with it?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse2" className="collapse" aria-labelledby="heading2"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    Yes, we have spare capacity in most sites. Please talk to our Executive Assistant 
                                    to have a temporary dedicated connection setup. Additional fees may apply.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading3">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse3" aria-expanded="false"
                                    aria-controls="collapse3">
                                        Can I rearrange the table and chairs around in the meeting/conference room?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse3" className="collapse" aria-labelledby="heading3"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    You're not allowed to rearrange the table and chairs in the meeting/conference room. 
                                    We can however arrange for special setup such as a seminar setup, classroom setup etc 
                                    prior to your usage. Please contact our Executive Assistant if you have such requirements.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading4">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse4" aria-expanded="false"
                                    aria-controls="collapse4">
                                        Where can my attendees / myself park our cars?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse4" className="collapse" aria-labelledby="heading4"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    There are usually public car parks around the vicinity of our buildings. 
                                    Please talk to our Executive Assistant for the best recommendation.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading5">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse5" aria-expanded="false"
                                    aria-controls="collapse5">
                                        How can I request for refreshments?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse5" className="collapse" aria-labelledby="heading5"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    Simply call the attention of our Executive Assistant to have your free coffee, 
                                    tea or filtered drinking water served or refilled.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading6">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse6" aria-expanded="false"
                                    aria-controls="collapse6">
                                        Can I get assistance to connect my laptop to your Smart TV or Projector?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse6" className="collapse" aria-labelledby="heading6"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    Definitely! Our Executive Assistant will gladly assist you with this.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-voffice" id="heading7">
                                <h5 className="mb-0">
                                    <a href="#!" className="d-block collapsed" data-toggle="collapse"
                                    data-target="#collapse7" aria-expanded="false"
                                    aria-controls="collapse7">
                                        I need a Time Extension for the space. What should I do?
                                    </a>
                                </h5>
                            </div>
                            <div id="collapse7" className="collapse" aria-labelledby="heading7"
                                data-parent="#accordionExample">
                                <div className="card-body">
                                    Please inform our Executive Assistant as early as possible and they will do 
                                    their best to accommodate your request. Do however be aware that sometimes, 
                                    extension is just not possible because there is a back to back bookings 
                                    after your original booking time.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
         );
    }
}
 
export default TabGuidlinesFAQ;