import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { apiCaller } from "../../services/apiCaller";
import * as moment from "moment";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";
import Midtrans from "../../components/Midtrans";
import swal from "sweetalert";
import * as numeral from "numeral";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceLoading: true,
            invoiceData: {},
            midtransTokenLoading: true,
            midtransTokenBank: "",
            midtransTokenCredit: ""
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    getClientInvoice() {
        apiCaller.get(`/getClientInvoices/${this.userData.client_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            const { match: { params } } = this.props;
            
            res.data.data.forEach(v => {
                if (parseInt(params.invoiceId) === v.id) {
                    this.setState({
                        invoiceLoading: false,
                        invoiceData: v
                    })
                }
            });
        })
    }

    showClientInvoiceTable() {
        if (!this.state.invoiceLoading) {
            return this.renderTableData();
        }
    }

    componentDidMount() {
        document.title = "vPortal | Invoices";
        this.getClientInvoice();
        this.getMidtransTokenCredit();
        this.getMidtransTokenBank();
    }

    renderButtonPaymentBank() {
        if (this.state.invoiceData.status === 'Unpaid') {
            return <Midtrans clientKey={"Mid-client-4BlC444gvNobfJOs"} token={this.state.midtransTokenBank} invoiceData={this.state.invoiceData}>
                <button className="btn btn-lg btn-primary w-100 pay-btn"><i class="mdi mdi mdi-cash-usd"></i> Bank Transfer</button>
            </Midtrans>;
        }
    }

    renderButtonPaymentCredit()
    {
        if (this.state.invoiceData.status === 'Unpaid')
        {
            return <Midtrans clientKey={"Mid-client-4BlC444gvNobfJOs"} token={this.state.midtransTokenCredit} invoiceData={this.state.invoiceData}>
                    <button className="btn btn-lg btn-outline-primary w-100 mt-lg-0 mt-2 pay-btn"><i class="mdi mdi-credit-card"></i> Credit Card</button>
                </Midtrans>;
        }
    }

    renderTableData() {
        const tableData = this.state.invoiceData;
        let result = [];

        let amount_due = tableData.amount_due;

        if (tableData.is_custom_invoice)
        {
            let amount_due_ori = parseInt(tableData.amount_due.replace(',', ''));
            amount_due = amount_due_ori * 0.11;
            amount_due = amount_due_ori + amount_due;
            amount_due = numeral(amount_due).format('0,0')
        }

        result.push(
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <div className="row m-0">
                                <div className="col-container-6">
                                    <span className="d-block font-size-sm opacity-06">Invoice ID</span>
                                    <label className="font-size-rg font-sb color-primary">
                                        {tableData.invoice_number}
                                    </label>
                                </div>
                                <div className="col-container-6">
                                    <div className="badge badge-pill badge-primary pt-2 font-size-rg float-right">
                                        {tableData.status}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 border-top">
                                <div className="col-md-3 p-0">
                                    <label className="font-size-rg font-sb mb-0 d-block">
                                        {tableData.company_name}
                                    </label>
                                    <div className="row m-0 mb-3">
                                        <FiMapPin className="font-size-rg font-sb d-flex" />
                                        <label className="font-size-sm font-sb mb-0 d-block ml-2">
                                            {tableData.location_name}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-flex font-size-sm opacity-06">Amount Due</span>
                                    <label className="font-size-rg font-sb">
                                        {amount_due}
                                    </label>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-flex font-size-sm opacity-06">Due Date</span>
                                    <label className="font-size-rg font-sb">
                                        {moment(tableData.date_due).format("D MMM YYYY")}
                                    </label>
                                </div>
                                <div className="col-md-3 p-0">
                                    <span className="d-block font-size-sm opacity-06">Due Generated</span>
                                    <label className="font-size-rg font-sb">
                                        {moment(tableData.date_generated).format("D MMM YYYY")}
                                    </label>
                                </div>
                                <div className="col-12 px-0 pb-2 font-size-sm">
                                    <span className="text-secondary">By making a payment, you have agreed our <Link target="_blank" className="font-weight-bold color-primary" to="/tnc">Terms & Condition</Link> and our <Link target="_blank" className="font-weight-bold color-primary" to="/privacy">Privacy Policy</Link>.</span>
                                </div>
                            </div>
                            <div className="border-top">
                                <div className="row">
                                    <div className="col-lg-6">
                                        {this.state.midtransTokenLoading ? this.loaderSpinner() : this.renderButtonPaymentBank()}
                                    </div>
                                    <div className="col-lg-6">
                                        {this.state.midtransTokenLoading ? this.loaderSpinner() : this.renderButtonPaymentCredit()}
                                        <div className="w-100 pt-1 text-lg-right text-left color-primary font-size-sm">
                                            <span>*3% of card handling fee will be amended</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        if (!tableData) {
            return <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <center>
                                <h3>No Data Available</h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        }

        return result;
    }

    loaderSpinner() {
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    viewInvoices() {
        let status = this.state.invoiceData.status;
        let invoiceId = this.state.invoiceData.id;

        if (status === 'Paid') {
            return <iframe title="PDF Invoice Receipt" style={{ margin: '0', border: '0', width: '100%', height: '800px' }} src={`https://vox.myvios.cloud/invoice/receipt/pdf/${invoiceId}#view=FitH`}></iframe>
        }
        else if (status === 'Unpaid') {
            return <iframe title="PDF Invoice Receipt" style={{ margin: '0', border: '0', width: '100%', height: '800px' }} src={`https://vox.myvios.cloud/invoice/proforma/pdf/${invoiceId}#view=FitH`}></iframe>
        }
    }

    getMidtransTokenBank() {

        let params = {
            invoice_id: this.props.match.params.invoiceId,
            client_first_name: this.userData.first_name,
            client_last_name: this.userData.last_name,
            client_email: this.userData.email,
            client_phone: this.userData.phone,
            payment_type: 'bank_transfer'
        }

        apiCaller.post(`/midtrans/getToken`, params, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                this.setState({
                    midtransTokenLoading: false,
                    midtransTokenBank: res.data.token
                })
            })
            .catch(err => {
                return swal("Oops", err.response.data.message, "error");
            });

    }

    getMidtransTokenCredit() {
        
        let params = {
            invoice_id: this.props.match.params.invoiceId,
            client_first_name: this.userData.first_name,
            client_last_name: this.userData.last_name,
            client_email: this.userData.email,
            client_phone: this.userData.phone,
            payment_type: 'credit_card'
        }

        apiCaller.post(`/midtrans/getToken`, params, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            this.setState({
                midtransTokenLoading: false,
                midtransTokenCredit: res.data.token
            })
        })
        .catch(err => {
            return swal("Oops", err.response.data.message, "error");
        });

    }

    render() {
        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            <label className="font-size-rg mb-16 pt-1 color-primary"><Link to="/invoices">< IoIosArrowBack className="mb-1" /> Back</Link></label>
                            <br />
                            <label className="font-size-rg mb-16 pt-1">Invoice Details</label>
                            {this.state.invoiceLoading ? this.loaderSpinner() : this.renderTableData()}
                            <div className="card m-b-30 invoice-iframe" style={{zIndex:'0'}}>
                                {this.viewInvoices()}
                            </div>
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
