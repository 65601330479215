import { apiCaller } from '../../services/apiCaller';
// import { apiCaller } from '../../services/apiCaller';
import React, { Component } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import swal from 'sweetalert';
import Navbar from '../../components/Navbar';
import FloatingButton from "../../components/FloatingButton";

class ManageProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            address: '',
            companyName: '',
            email: '',
            whatsapp: '',
            avatarText: '',
            isLoading: false
        }
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handlePhoneNumber = (val) => {
        this.setState({
            whatsapp: val
        });
    }

    createAvatarText = (userData) => {
        const firstChar = userData.first_name.substring(0, 1);
        const lastChar = userData.last_name ? userData.last_name.substring(0, 1) : "";

        return firstChar+lastChar;
    }

    fetchUserData(){
        const userData = this.userData;

        this.setState({
            firstName: userData.first_name? userData.first_name : "",
            lastName: userData.last_name? userData.last_name : "",
            address: userData.address ? userData.address : "",
            companyName: userData.company_name? userData.company_name : "",
            email: userData.email? userData.email : "",
            whatsapp: userData.whatsapp? userData.whatsapp : "",
            avatarText: this.createAvatarText(userData)
        });
    }

    refreshLocalData = async (data) => {
        this.setState({
            firstName: data.first_name? data.first_name : "",
            lastName: data.last_name? data.last_name : "",
            address: data.address ? data.address : "",
            companyName: data.company_name? data.company_name : "",
            email: data.email? data.email : "",
            whatsapp: data.whatsapp? data.whatsapp : "",
            avatarText: this.createAvatarText(data)
        })

        const userData = this.userData;
        const newData = {};
        Object.keys(userData).map(v => {
            if (v !== "token") {
                return data[v] ? newData[v]=data[v] : newData[v]=userData[v];
            }
            
            return [];
        })
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(newData));
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    handleSave() {
        const dataToPost = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            address: this.state.address,
            company_name: this.state.companyName,
            email: this.state.email,
            whatsapp: this.state.whatsapp
        };
        
        this.setState({
            isLoading: true
        });

        apiCaller.post(`/updateClientInfo`, dataToPost, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then(res => {
            swal("Nice", "Your profile has been updated", "success");
            this.setState({
                isLoading: false
            });
            this.refreshLocalData(res.data);
        }).catch(err => {
            swal("Oops", err.response.data.message, "error");
            this.setState({
                isLoading: false,
            });
        })
    }

    componentDidMount(){
        document.title = "vPortal | Manage Profile";
        this.fetchUserData()
    }

    render() { 
        const containerStyle = {
            marginTop: "66px"
        };

        const avatarImg = {
            width: 100,
            height: 100,
            backgroundImage: "linear-gradient(to left top, #951942, #911952, #891f61, #7d276f, #6d307b, #723b89, #764698, #7a51a6, #9a63bc, #bb75d1, #dd88e6, #ff9bfb)",
            display: "block",
            margin: "0 auto",
            verticalAlign: "middle",
            lineHeight: "100px",
            fontSize: "2.7rem",
            color: "#fff",
            borderRadius: "100%"
        }

        return (
          <div>
            <Navbar />
            <div className="container pb-3" style={containerStyle}>
              <div className="row">
                <div className="col-12 mt-3 text-center">
                  <h4>My Profile</h4>
                </div>
                <div className="col-12 text-center mt-1">
                  <div style={avatarImg}>{this.state.avatarText}</div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>
                            Firstname<i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.firstName}
                            className="form-control"
                            placeholder="Firstname"
                            disabled
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Lastname<i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.lastName}
                            className="form-control"
                            placeholder="Lastname"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Address</label>
                          <input
                            type="text"
                            name="address"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.address}
                            className="form-control"
                            placeholder="Address"
                            disabled
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Company Name<i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            name="companyName"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.companyName}
                            className="form-control"
                            placeholder="Company Name"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>
                            Email<i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            name="email"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.email}
                            className="form-control"
                            placeholder="Email"
                            disabled
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Whatsapp No.<i className="text-danger">*</i>
                          </label>
                          <PhoneInput
                            value={this.state.whatsapp}
                            onChange={(val) => this.handlePhoneNumber(val)}
                            className="form-control"
                            placeholder="+62 812 1945 1708"
                            disabled
                          />
                          <label className="text-danger font-italic font-size-sm mt-2">
                            Please use the country code. eg: +62 (Indonesia)
                          </label>
                        </div>
                      </div>

                      <div className="row mt-1 mb-2">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => this.handleSave()}
                            disabled
                          >
                            {this.state.isLoading ? (
                              <div
                                className="spinner-border text-secondary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Please Ask Our Staff To Change Your Profile"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FloatingButton />
          </div>
        );
    }
}

export default ManageProfile;