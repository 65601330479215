import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Tabs from "../FacilitiesBooking/components/FacilityBookTabs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactLoading from 'react-loading';
import { apiCaller } from '../../services/apiCaller';
import { IoIosArrowBack } from "react-icons/io";

import "./FacilitiesBookingShow.css";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import FloatingButton from "../../components/FloatingButton";


class FacilitiesBookingShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facilities: {},
            isLoading: true,
            lat: "",
            lng: ""
        };
    }

    componentDidMount(){
        this.fetchFacilityData();
    }

    fetchFacilityData = async () => {
        const { match: { params } } = this.props;
        apiCaller.get(`/getFacilityDetails/${params.facilityId}`)
        .then(res => {

            let coordinates = res.data.coordinates;
            let lat = '';
            let lng = '';
            
            if (coordinates !== '-') {
                let coordinatesArr = String(coordinates).split(' ');
                lat = coordinatesArr[0];
                lng = coordinatesArr[1];
            }
                   
            this.setState({
                facilities: res.data,
                isLoading: false,
                lat: lat,
                lng: lng,
            })

            this.renderImage()
        }).catch(error => console.log(error));
    }

    openPopup(marker) {
        if (marker && marker.leafletElement) {
            window.setTimeout(() => {
                marker.leafletElement.openPopup()
            })
        }
    }

    showMap = () => {
        if (this.state.lat) {
            return (<Map center={[this.state.lat, this.state.lng]} zoom={23} minZoom={5} className="location-map">
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[this.state.lat, this.state.lng]} ref={this.openPopup}>
                    <Popup>
                        {this.state.facilities.address}
                    </Popup>
                </Marker>
            </Map>)
        }
    }

    renderImage() {
        const images = Object.values(this.state.facilities.images);
        let sliderImages = [];

        images.map(imgData => {
            let image = imgData ? `url('${imgData}')` :`url(${require('../../assets/images/no-image.png') })`;

            const styleBanner = {
                width: "100%",
                height: "450px",
                backgroundImage: image,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                marginTop: 10,
                borderRadius: "15px",
            }

            return sliderImages.push(
                <div className="px-1">
                    <div style={styleBanner}></div>
                </div>
            )
        })
        
        return sliderImages;
    }

    render() { 
        var couroselSet = {
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        const backBtn = {
            position: "absolute",
            top: "9px",
            margin: "16px",
            zIndex: 99,
            color: "white",
            background: "#9a2b59",
            borderRadius: 15,
            paddingRight: 10,
        };

        const contentIcnBack = {
            width: "24px",
            height: "24px",
            padding: "4px",
        };

        if(this.state.isLoading){
            return (
                <>
                    <ReactLoading type="bubbles" style={{
                        margin: "0 auto",
                        fill: "rgb(136, 136, 136)",
                        height: 127,
                        width: 127
                    }} />
                </>
            )
        }

        return (
            <>
                <Navbar />
                <div className="facility-book-show-container">
                    <div className="col-lg-8 p-0 mx-auto" > 
                        {
                            this.state.isLoading ? 
                            <ReactLoading type="bubbles" style={{  
                                margin: "0 auto",
                                fill: "rgb(136, 136, 136)",
                                height: 127,
                                width: 127
                            }} />
                            :
                            <Slider {...couroselSet}>
                                {this.state.facilities.images ? this.renderImage() : ""}
                            </Slider>
                        }
                        
                        <Link to="/facility-booking" style={backBtn}>
                            <IoIosArrowBack style={contentIcnBack} /> Facility Booking
                        </Link>
                        <div className="row m-0">
                            <div className="col-12 col-xl-8 p-0 mb-xl-5">
                                <Tabs data={this.state.facilities} />
                            </div>
                            <div className="col-12 col-xl-4">
                                {this.showMap()}
                            </div>
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default FacilitiesBookingShow;