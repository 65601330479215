import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from "../../components/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import { apiCaller } from '../../services/apiCaller';
import ReactLoading from "react-loading";
import CardData from "./components/CardData";
import FloatingButton from "../../components/FloatingButton";
import { ToastContainer } from "react-toastify";

class BookingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true
        }
    }

    getBookingHistory() {
        apiCaller.get("/getMyBookings")
        .then(res => {
            this.setState({
                isLoading: false,
                bookingHistory: res.data
            });
        })
        .catch(err => {
            this.setState({
                isLoading: false
            });
        });
    }

    displayData() {
        const bookingData = Object.values(this.state.bookingHistory);

        return bookingData.map((v, k) => {
            return <CardData key={k} data={v} />;
        });
    }

    componentDidMount(){
        document.title = "vPortal | Booking History";
        this.getBookingHistory();
    }
    
    render() { 
        return (
            <>
                <Navbar />
                <div className="top-container container" style={{ marginTop: 15 }}>
                    <div className="row">
                        <div className="col-12 pt-3">
                            <label className="font-size-rg mb-16 pt-1 color-primary">
                                <Link to="/dashboard">
                                    <IoIosArrowBack className="mb-1" /> Dashboard
                                </Link>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                this.state.isLoading ? 
                                <div className="col-2 mx-auto mt-5 text-center">
                                    <ReactLoading type="bubbles" color="#888888" />
                                </div>
                                :
                                this.displayData()
                            }
                        </div>
                        <ToastContainer position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover />
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default BookingHistory;