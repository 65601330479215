import React, { Component } from "react";
import bgTerm from "../../components/bg-term.jpg";
import logo from "../../components/logo.svg";

class PrivacyPolicy extends Component {
    
    render() {
        return (
            <>  
                <img src={ bgTerm } height="auto" width="100%" alt="bg-term"/>
                    <div className="top-container container row mx-auto mb-3 p-3" style={{ position:'absolute', top:'0', left:'0', right:'0' }}>
                        <div className="col-md-12 p-0">
                            <div className="card">
                                <div className="card-body">
                                    <img src={logo} width="150px" alt="bg-logo-voffice" style={{marginBottom:"0.5rem"}}/>
                                    <div className="d-lg-flex">
                                        <div className="">
                                            <h3 className="m-0">Privacy Policy</h3>
                                            <h4 className="opacity-06 m-0" style={{ fontWeight:"200" }}>
                                                <i>
                                                Terms and Conditions
                                                </i>
                                            </h4>
                                        </div>
                                    </div>
                                    <hr/>
                                    <ol type="I" style={{ fontSize: "1.2rem" }}>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Pendahuluan  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Introduction</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Kebijakan Privasi ini dirancang untuk membatu para pihak mengetahui dan memahami bagaimana
                                                    vOffice akan menggunakan Informasi Rahasia dan/atau data pribadi Klien vOffice.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    This Privacy Policy is designed to help parties know and understand how 
                                                    vOffice will use the Confidential Information and / or personal data of vOffice Clients.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Kebijakan Privasi ini berlaku untuk semua orang dan instansi yang terlibat dalam vOffice. Dengan
                                                    menggunakan layanan vOffice ini para pihak dianggap telah membaca dan menyetujui seluruh
                                                    ketentuan yang diatur dalam Kebijakan Privasi ini.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    This Privacy Policy applies to all people and agencies involved in vOffice. By using this vOffice service, 
                                                    the parties are deemed to have read and agreed to all the terms set out in this Privacy Policy.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Informasi Rahasia  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Confidential Information</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Bahwa dalam rangka penggunaan kantor virtual vOffice, para pihak dapat diminta untuk
                                                    mengungkapkan Informasi Rahasia kepada vOffice.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    That in the context of using the vOffice virtual office, parties may be asked to disclose 
                                                    Confidential Information to vOffice.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem", marginBottom:"1rem" }}>
                                                    Untuk kepentingan vOffice, definisi dari Informasi Rahasia adalah sebagai berikut:
                                                    <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                        For vOffice purposes, the definition of Confidential Information is as follows:
                                                    </i></p>
                                                    <ol type="a" style={{ fontSize: "1rem" }}>
                                                        <li style={{ marginTop:"1rem" }}>
                                                            Setiap informasi dan/atau data pribadi yang diserahkan secara mandiri atau data pribadi
                                                            yang berhubungan dengan para pihak yang secara langsung maupun tidak langsung
                                                            berkaitan dengan vOffice, baik secara lisan, tertulis, grafik, magnetik, elektronik atau
                                                            bentuk lain yang secara langsung maupun tidak langsung disampaikan oleh para pihak
                                                            kepada vOffice;
                                                        </li>
                                                        <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                            Any personal information and / or data submitted independently or personal data relating 
                                                            to parties that are directly or indirectly related to vOffice, whether orally, in writing, 
                                                            graphic, magnetic, electronic or other forms that are directly or indirectly conveyed by 
                                                            the parties to vOffice;
                                                        </i></p>
                                                        <li style={{ marginTop:"0.5rem" }}>
                                                            Segala komunikasi antara para pihak, baik secara lisan maupun tulisan yang diketahui atau
                                                            semestinya diketahui oleh para pihak untuk menjadi rahasia yang memuat alamat email,
                                                            nomor telepon, password, alamat, foto, dan lain-lain; atau
                                                        </li>
                                                        <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                            All communications between the parties, either orally or in writing that are known or should 
                                                            be known by the parties to be confidential, containing email addresses, telephone numbers, 
                                                            passwords, addresses, photographs, etc. or
                                                        </i></p>
                                                        <li style={{ marginTop:"0.5rem" }}>
                                                            Informasi data pribadi yang diserahkan ketika mengisi data-data pembayaran pada saat
                                                            Klien melakukan aktivitas transaksi pembayaran melalui situs vOffice, termasuk namun
                                                            tidak terbatas pada data rekening bank, kartu kredit, virtual account, instant payment,
                                                            internet banking, dan lain-lain.
                                                        </li>
                                                        <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                            Personal data information submitted when filling in payment data when the Client performs 
                                                            payment transaction activities through the vOffice site, including but not limited to bank 
                                                            account data, credit cards, virtual accounts, instant payments, internet banking, and others.
                                                        </i></p>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Informasi yang Tidak Dilingungi  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Unprotected Information</i></h4>
                                            <label style={{ fontSize: "1rem", marginTop:"1rem" }}>Untuk kepentingan vOffice, yang dimaksud dengan Informasi Yang Tidak Dilindungi adalah sebagai berikut:</label>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Kebijakan Privasi ini dirancang untuk membatu para pihak mengetahui dan memahami bagaimana
                                                    vOffice akan menggunakan Informasi Rahasia dan/atau data pribadi Klien vOffice.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Information which at the time of its disclosure is already in legal ownership or is available 
                                                    on vOffice which is obtained by lawful means and from other sources who is allowed to reveal it.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Kebijakan Privasi ini berlaku untuk semua orang dan instansi yang terlibat dalam vOffice. Dengan
                                                    menggunakan layanan vOffice ini para pihak dianggap telah membaca dan menyetujui seluruh
                                                    ketentuan yang diatur dalam Kebijakan Privasi ini.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Information that has been or will become publicly available is not a violation of this Privacy Policy.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Tanggung Jawab  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Responsibilities</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    vOffice berkomitmen untuk tidak mengungkapkan dan akan mengambil seluruh tindakan yang
                                                    diperlukan untuk melindungi kerahasiaan Informasi Rahasia, serta menghindari pengungkapan atau
                                                    penyalahgunaan Informasi Rahasia dan berjanji hanya menggunakannya untuk kepentingan vOffice.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    vOffice is committed not to disclose and will take all necessary measures to protect the confidentiality 
                                                    of Confidential Information, as well as avoid disclosing or misuse of Confidential Information and 
                                                    promises to only use it for vOffice's interests.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Tanpa membatasi hal sebagaimana diatur pada ayat (1) diatas, vOffice diperbolehkan untuk
                                                    mengungkapkan Informasi Rahasia kepada anggota-anggotanya, direksinya, karyawan-karyawannya,
                                                    afiliasinya, subkontraktor, agennya dan/atau pihak yang ditunjuk. vOffice setuju untuk mengambil
                                                    segala tindakan pencegahan dan perlindungan yang diperlukan terhadap segala pengungkapan yang
                                                    tidak sah, serta meminta kepada pihak-pihak yang menerima Informasi Rahasia untuk tunduk pada
                                                    ketentuan yang diatur dalam Kebijakan Privasi ini.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Without limiting the matters as stipulated in paragraph (1) above, vOffice is allowed to disclose Confidential Information 
                                                    to its members, directors, employees, affiliates, subcontractors, agents and / or appointed parties. vOffice agrees to take 
                                                    all necessary precautions and protections against any unauthorized disclosure, as well as ask the parties who receive 
                                                    Confidential Information to comply with the provisions set out in this Privacy Policy. 
                                                </i></p>
                                                <li style={{ marginTop:"1rem" }}>
                                                    vOffice sepakat untuk menjamin dan membebaskan para pihak terhadap setiap dan segala tindakan,
                                                    klaim, kerusakan dan kerugian yang terjadi pada para pihak dikarenakan pengungkapan yang tidak
                                                    sah terhadap Pihak Ketiga yang dibuat secara bertentangan dengan Kebijakan Privasi ini.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    vOffice agrees to guarantee and release the parties against any and all actions, claims, damages 
                                                    and losses that occur to the parties due to unauthorized disclosures of third parties which are 
                                                    made contrary to this Privacy Policy. 
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    vOffice dengan ini bertanggungjawab untuk menyimpan semua dokumen yang memuat atau
                                                    merupakan Informasi Rahasia dengan baik dan terpisah dengan informasi yang bersifat umum.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    vOffice is hereby responsible for keeping all documents that contain or constitute 
                                                    Confidential Information properly and are separate from general information.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Pengungkapan Informasi Rahasia Kepada Pihak Ketiga  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Disclosure Of Confidential Information To Third Parties</i></h4>
                                            <label style={{ fontSize: "1rem", marginTop:"1rem" }}>Kecuali untuk pemberian informasi dalam kepentingan penggunaan kantor virtual vOffice, sebelum
                                                pengungkapan Informasi Rahasia kepada Pihak Ketiga, termasuk namun tidak terbatas pada konsultan,
                                                akuntan publik atau pejabat lokal, Penerima Informasi akan terlebih dahulu melakukan hal-hal sebagai
                                                berikut:
                                                <br/>
                                                <i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Except for providing information in the interests of using the vOffice virtual office, 
                                                    prior to disclosing Confidential Information to Third Parties, including but not limited 
                                                    to consultants, public accountants or local officials, Information Recipients will first 
                                                    do things as the following:
                                                </i>
                                            </label>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Mendapatkan persetujuan secara tertulis terlebih dahulu dari para pihak untuk mengungkapkan 
                                                    Informasi Rahasia kepada Pihak Ketiga tersebut; 
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Obtain prior written consent from the parties to disclose Confidential Information to the Third Party; 
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Memastikan bahwa Pihak Ketiga tersebut akan tunduk pada ketentuan yang diatur dalam Kebijakan Privasi ini; dan
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Ensuring that the Third Party will comply with the provisions set out in this Privacy Policy; and
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Meminta untuk segera mengembalikan Informasi Rahasia paling lambat 2 (dua) hari kalender setelah Pihak Ketiga tersebut menyelesaikan pekerjaannya.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Request to immediately return the Confidential Information no later than 2 (two) calendar days after the Third Party has completed its work.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Jangka Waktu Kebijakan Privasi  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Period Of Privacy Policy</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Kebijakan Privasi ini berlaku secara terus menerus selama berlangsung, terhitung sejak disetujuinya.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    This Privacy Policy is valid continuously for as long as it is approved. 
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Para pihak tetap memiliki kewajiban untuk mematuhi ketentuan dalam Kebijakan Privasi ini
                                                    walaupun layanan AyoReal ini berakhir.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    The parties still have an obligation to comply with the provisions of this Privacy Policy even though the service ends.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Pengecualian  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Exceptions</i></h4>
                                            <label style={{ fontSize: "1rem", marginTop:"1rem" }}>
                                                vOffice memiliki kewajiban untuk mengungkapkan Informasi Rahasia apabila memang hal tersebut
                                                disyaratkan oleh undang-undang yang berlaku di Indonesia, seperti dimintakan oleh instansi yang berwenang
                                                termasuk namun tidak terbatas pada OJK, Komisi Pemberantasan Korupsi (KPK), Kepolisian Republik
                                                Indonesia dan pengadilan.
                                                <br/>
                                                <i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    vOffice has the obligation to disclose Confidential Information if indeed this is required by applicable 
                                                    laws in Indonesia, as requested by the competent authorities including but not limited to OJK, the 
                                                    Corruption Eradication Commission (KPK), the Indonesian National Police and the courts. 
                                                </i>
                                            </label>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Pengalihan  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Transfer</i></h4>
                                            <label style={{ fontSize: "1rem", marginTop:"1rem" }}>
                                                Kebijakan Privasi ini tidak dapat dialihkan oleh para pihak kepada pihak manapun tanpa adanya persetujuan
                                                tertulis dari pihak lainnya.
                                                <br/>
                                                <i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    This Privacy Policy cannot be transferred by the parties to any party without the written consent of the other party. 
                                                </i>
                                            </label>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Hukum yang Berlaku dan Penyelesaian Sengketa  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Applicable Law And Dispute Settlement</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Kebijakan Privasi ini dibuat, ditafsirkan dan dilaksanakan berdasarkan hukum Negara Republik
                                                    Indonesia.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    This Privacy Policy is prepared, interpreted and implemented under the laws of the Republic of Indonesia.
                                                </i></p>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Setiap perselisihan yang terjadi sehubungan dengan penerapan Kebijakan Privasi ini akan
                                                    diselesaikan secara musyawarah untuk mufakat.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    Any dispute that occurs in connection with the application of this Privacy Policy will be resolved by deliberation to reach a consensus.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Apabila dalam 30 (tiga puluh) hari penyelesaian secara musyawarah tidak berhasil mencapai
                                                    mufakat, maka para pihak sepakat untuk menyelesaikan permasalahannya di Pengadilan Negeri
                                                    Jakarta Selatan.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    If within 30 (thirty) days the deliberative settlement fails to reach consensus, the parties agree to resolve the problem at the South Jakarta District Court.
                                                </i></p>
                                            </ol>
                                        </li>
                                        <li style={{padding: '5px'}}>
                                            <h4><b>Lain-Lain  </b><i className="opacity-06" style={{ fontWeight:"200" }}>Miscellaneous</i></h4>
                                            <ol style={{ fontSize: "1rem" }}>
                                                <li style={{ marginTop:"1rem" }}>
                                                    Dalam hal ada salah satu atau lebih ketentuan dalam Kebijakan Privasi ini dinatakan tidak sah
                                                    dan/atau tidak berlaku dan/atau tidak dapat dilaksanakan berdasarkan suatu putusan pengadilan,
                                                    maka tidak membuat ketentuan lain yang diatur dalam Kebijakan Privasi ini menjadi batal.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    In the event that one or more provisions in this Privacy Policy are stated invalid and / or 
                                                    invalid and / or cannot be implemented based on a court decision, then it does not make other 
                                                    provisions set out in this Privacy Policy null and void.
                                                </i></p>
                                                <li style={{ marginTop:"0.5rem" }}>
                                                    Voffice berhak untuk mengubah ketentuan dalam Kebijakan Privasi ini dalam rangka
                                                    penyempurnaan dan penyesuaian dengan ketentuan peraturan perundang-undangan yang berlaku
                                                    di Republik Indonesia.
                                                </li>
                                                <p><i className="opacity-06" style={{ marginBottom:"1rem", fontWeight:"200" }}>
                                                    vOffice has the right to change the provisions in this Privacy Policy in order to improve 
                                                    and adjust to the provisions of the laws and regulations in force in the Republic of Indonesia.
                                                </i></p>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
}

export default PrivacyPolicy;