import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import { apiCaller } from "../../services/apiCaller";
import * as moment from "moment";
import { Link } from "react-router-dom";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callLoading: true,
            callData: {},
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    getClientCall() {
        apiCaller.get(`/getClientCalls/${this.userData.client_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                this.setState({
                    callLoading: false,
                    callData: res.data.data
                })
            })
    }

    showClientCallTable() {
        if (!this.state.callLoading) {
            return this.renderTableData()
        }
    }

    componentDidMount() {
        document.title = "vPortal | Calls";
        this.getClientCall();
    }

    renderTableData() {
        const tableData = this.state.callData;
        let result = [];

        for (let i = 0; i < tableData.length; i++) {
            result.push(
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-light mb-3">
                            {/* <div class="card-header">Header</div> */}
                            <div className="card-body">
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <span className="d-block font-size-sm opacity-06">From</span>
                                        <label className="font-size-rg font-sb color-primary d-block mb-0">
                                            {tableData[i].caller_name}
                                        </label>
                                        {tableData[i].caller_company_name ? 
                                            <label className="font-size-sm font-sb d-block">
                                                {tableData[i].caller_company_name}
                                            </label> : ""}
                                    </div>
                                    <div className="col-md-6 p-0 mb-2 text-md-right text-left">
                                        <div className="badge badge-pill badge-primary pt-2 font-size-rg">
                                            {this.translateStatus(tableData[i].status)}
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 border-top">
                                    <div className="col-md-12 p-0">
                                        <span className="d-block font-size-sm opacity-06 mb-1">Message</span>
                                        <label className="font-size-rg font-sb color-primary d-block bg-primary02 p-2">
                                            &quot;{tableData[i].message}&quot;
                                        </label>
                                    </div>
                                </div>
                                <div className="row m-0 pt-2">
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Caller Number</span>
                                        <label className="font-size-rg font-sb d-block">
                                            {tableData[i].caller_no}
                                        </label>
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Date</span>
                                        <label className="font-size-rg font-sb">
                                            { moment(tableData[i].date_time).format("D MMM YYYY HH:mm") }
                                        </label>
                                    </div>
                                    <div className="col-md-3 p-0">
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Operator</span>
                                        <label className="font-size-rg font-sb">
                                            { tableData[i].created_by_name }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (tableData.length < 1) {
            return <div className="row">
                <div className="col-12">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <center>
                                <h3>No Data Available</h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        }

        return result;
    }

    translateStatus(status) {
        let res = '';
        if (status === '0') {
            res += 'Call dropped';
        }
        else if (status === '1') {
            res += 'Failed to client';
        }
        else if (status === '2') {
            res += 'Call forwarded';
        }
        else if (status === '3') {
            res += 'Answered but not forwarded';
        }
        else if (status === '4') {
            res += 'Call dropped (rejected by our staff)';
        }

        return res;
    }

    loaderSpinner(){
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    render() {
        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3 h-100vh">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            <label className="font-size-rg mb-16 pt-1 color-primary"><Link to="/dashboard">< IoIosArrowBack className="mb-1" /> <span>Back</span></Link></label>
                            <br />
                            <label className="font-size-rg mb-16 pt-1">Last call(s)</label>
                            {this.state.callLoading ? this.loaderSpinner() :this.renderTableData()}
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
