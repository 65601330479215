import React, { Component } from 'react';
import { Link } from "react-router-dom";

class BannerCard extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }

    redirectUrlClicked(link){
        window.open(link)
    }
    
    render() { 

        return (
            <div className="px-2">
                <a href="#foo">
                    <img alt={this.props.description} src={this.props.image_url} onClick={()=> {this.redirectUrlClicked(this.props.redirect_url)}} className="img-fluid w-100"/>
                </a>
            </div>
        );
    }
}
 
export default BannerCard;