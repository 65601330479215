import React, { Component } from 'react';
import { Link } from "react-router-dom";
import think from "../../components/think.svg";
import Navbar from '../../components/Navbar';

class RewardPage extends Component {
    componentDidMount(){
        document.title = "vPortal | Comming Soon";
    }

    render() {

        const containerStyle = {
            paddingTop: 80,
            paddingBottom: 20,
            height: "100%",
        };

        return (
            <>
                <Navbar />
                <div className="container-fluid" style={containerStyle}>
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-6 mx-auto pb-5">
                            <div className="row mt-5">
                                <div className="col-12 mt-5">
                                    <img src={think} alt="Mask-Group" border="0" style={{ marginTop: "-50px" }}/>
                                </div>
                                <div className="col-12 text-center mt-3">
                                    <h2>Coming Soon!</h2>
                                    <h5>Stay tuned for this feature</h5>
                                    <button className="font-size-rg mb-16 btn btn-lg btn-primary mt-2"><Link to="/dashboard">Back To Dashboard</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RewardPage;