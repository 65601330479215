import React, { Component } from 'react';
import { apiCaller } from '../../services/apiCaller';
import swal from "sweetalert";
import Navbar from "../../components/Navbar";


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            oldPassword: '',
            password: '',
            retypePassword: '',
            isLoading: false,
            passwordMatch: true,
            passwordConfirmMatch: true
        }
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        
        if(e.target.name === 'password'){
            this.setState({
                passwordMatch: e.target.value !== this.state.oldPassword,
            });
        }
        
        if(e.target.name === 'retypePassword'){
            this.setState({
                passwordConfirmMatch: e.target.value === this.state.password,
            });
        }
    }

    fetchUserData(){
        const userData = this.userData;

        this.setState({
            oldPassword: userData.old_password? userData.old_password : "",
        });
    }

    refreshLocalData = async (data) => {
        this.setState({
            oldPassword: data.old_password? data.old_password : "",
        })

        const userData = this.userData;
        const newData = {};
        Object.keys(userData).map(v => {
            if (v !== "token") {
                return data[v] ? newData[v]=data[v] : newData[v]=userData[v];
            }
            
            return [];
        })
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(newData));
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    handleSave() {
        const dataToPost = {
            client_id: this.userData.client_id,
            old_password: this.state.oldPassword,
            password: this.state.password,
        };
        
        this.setState({
            isLoading: true
        });

        apiCaller.post(`${process.env.REACT_APP_API_URL}/resetPassword`, dataToPost, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then(res => {
            swal("Nice", "Your password has been updated", "success");
            this.setState({
                password: '',
                retypePassword: '',
                isLoading: false
            });
            this.refreshLocalData(res.data);
        }).catch(err => {
            swal("Oops", err.response.data.message, "error");
            this.setState({
                password: '',
                retypePassword: '',
                isLoading: false,
            });
        })
    }

    render() { 

        const containerStyle = {
          marginTop: "66px",
        };

        return (
          <div>
            <Navbar />
            <div className="container pb-3" style={containerStyle}>
            <div className="row">
                        {/* <div className="col-12 mt-3 text-center">
                            <h4>Change Username</h4>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Username<i className="text-danger">*</i></label>
                                            <input type="password" name="password" onChange={(e) => this.handleChange(e)} value={this.state.password} className={ this.state.passwordMatch ? "form-control" : " form-control is-invalid"  } placeholder="Username" /><div className="invalid-feedback">
                                                The New Password should not be the same as the current one!
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-2">
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary w-100" onClick={() => this.handleSave()} disabled={this.state.isLoading ? true : false}>
                                                {
                                                    this.state.isLoading ? <div className="spinner-border text-secondary" role="status"><span className="sr-only">Loading...</span></div> : 'Save'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"> */}
                        <div className="col-12 mt-3 text-center">
                            <h4>Change Password</h4>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Old Password<i className="text-danger">*</i></label>
                                            <input type="password" name="oldPassword" onChange={(e) => this.handleChange(e)} value={this.state.oldPassword} className="form-control" placeholder="Old Password" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>New Password<i className="text-danger">*</i></label>
                                            <input type="password" name="password" onChange={(e) => this.handleChange(e)} value={this.state.password} className={ this.state.passwordMatch ? "form-control" : " form-control is-invalid"  } placeholder="New Password" /><div className="invalid-feedback">
                                                The New Password should not be the same as the current one!
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Re-Type Password<i className="text-danger">*</i></label>
                                            <input type="password" name="retypePassword" onChange={(e) => this.handleChange(e)} value={this.state.retypePassword} className={ this.state.passwordConfirmMatch ? "form-control" : " form-control is-invalid"  } placeholder="Re-Type Password" />
                                            <div className="invalid-feedback">
                                                Password doesn't match!
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1 mb-2">
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary w-100" onClick={() => this.handleSave()} disabled={this.state.isLoading ? true : false}>
                                                {
                                                    this.state.isLoading ? <div className="spinner-border text-secondary" role="status"><span className="sr-only">Loading...</span></div> : 'Save'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        );
    }
}
 
export default ChangePassword;