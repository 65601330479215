import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IoIosArrowBack } from "react-icons/io";
import access from "../../components/access.png"
import "../FacilitiesBooking/FacilitiesBookingShow.css";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import FloatingButton from "../../components/FloatingButton";
import Select from 'react-select';
import bgRoom from '../../assets/images/bg-coworking.jpg';
import Axios from 'axios';
import swal from 'sweetalert';


class CoworkingPage extends Component {
    constructor(props) {
        super(props);
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.state = {
            coworkingData: {},
            bookingData: {
              client_id: this.userData.client_id,
              date: null,
              location_id: null
            },
            isLoading: true,
        };
    }

    componentDidMount(){
        // this.fetchFacilityData();
        this.isCoworking();
    }

    isCoworking()
    {
        Axios.get(`https://vox.myvios.cloud/api/portal/is-coworking/${this.userData.client_id}`)
        .then(res => {
            this.setState({
              coworkingData: res.data,
              isLoading: false
            });
        })
        .catch(err => {
            console.log(err);
        })
    }

    options(){
        let locations = this.state.coworkingData.locations;
        let locationsArr = [];

        if (Object.keys(this.state.coworkingData).length !== 0) {
          
          locations.map(value => {
            // let locationDetails = [];
            // value[1].map(value2 => {
            //     return locationDetails.push({
            //         label: value2.name, value: value2.id
            //     })
            // })

            return locationsArr.push({
                label: value.name,
                value: value.id
            });
        })
        }
        
        return locationsArr;
    }

    getWeekdays = date => {
        const day = date.getDay();

        return day !== 0 && day !== 6;
    };

    handleChangeDate = date => {
        const dateParam = date ? date : new Date();
        return this.setState({
            bookingData: {
              ...this.state.bookingData,
              date: dateParam,
            },
            timeSlotLoading: true
        });
    };

    handleChange(e){
        return this.setState({
          bookingData: {
            ...this.state.bookingData,
            location_id: e.value
          }
        })
    }

    submitBooking = async () => {
      try {

        this.setState({
          isLoading:!this.state.isLoading
        })

        const coworkingBooking = await Axios
        .post(`https://vox.myvios.cloud/api/portal/coworking/store`,
          {
            ...this.state.bookingData,
            date: moment(this.state.bookingData.date).format("YYYY-MM-DD")
          }
        )
        .then(res => {
            swal({
              title: "Success",
              text: `You have successfully booked coworking`,
              icon: "success",
              dangerMode: true,
            }).then((confirmBooking) => {
                this.setState({
                  isLoading: false
                });
                confirmBooking && window.location.reload();  
            });
        }).catch(err => {
            swal({
              title: "Oops",
              text: err.response.data.message,
              icon: "error",
              dangerMode: true,
            }).then((confirmBooking) => {
                this.setState({
                  isLoading: false
                });
                confirmBooking && window.location.reload();  
            });
        })

        

        // if (coworkingBooking.status === 200) {
        //   this.setState({
        //     isLoading:!this.state.isLoading
        //   })

        //   return swal({
        //     title: "Success",
        //     text:
        //         `You have successfully booked coworking`,
        //     icon: "success",
        //     // buttons: true,
        //     dangerMode: true,
        //   }).then((confirmBooking) => {
        //       confirmBooking && window.location.reload();  
        //   });
        // }


      } catch (error) {

        return {
            status: false,
            message: "Server is currently down!"
          }
      }
    }

    showCoworkingPage = (hasCoworking) => {

      const stylesSelect = {
          container: base => ({
              ...base,
              flex: 1,
              textAlign: 'left'
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                  ...styles,
                  color: '#000',
                  cursor: isDisabled ? 'not-allowed' : 'default',
              };
          },
      };

      const backBtn = {
            // position: "absolute",
            // margin: "16px",
            zIndex: 99,
            color: "white",
            background: "#9a2b59",
            borderRadius: 15,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5, 
        };

      const contentIcnBack = {
        width: "24px",
        height: "24px",
        padding: "4px",
      };

      if (hasCoworking) {
        return(
          <>
            <Link to="/dashboard" style={backBtn}>
                <IoIosArrowBack style={contentIcnBack} /> Dashboard
            </Link>
            <div className="row align-items-stretch mt-3">
              <div className="col-lg-4 mb-3 mb-md-0">
                <div className="card card-body h-100" style={{color: "white", border: 0, background: `linear-gradient(2.72deg, rgb(172 50 103 / 20%) 14.21%, rgb(144 43 87 / 40%) 34.94%, rgb(125 42 73 / 60%) 61.47%, rgb(119, 39, 69) 97.12%), url(${bgRoom}) no-repeat center center / cover`}}>
                  <h1>Co-Woking</h1>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card card-body">
                  <div className="mb-3">
                    <label className="d-block font-size-rg mb-0 font-sb">LOCATION</label>
                    <label className="d-block font-size-sm font-rg opacity-06">Choose an available coworking location</label>
                    <Select
                        placeholder="Select Location"
                        onChange={(e) => this.handleChange(e)}
                        styles={stylesSelect}
                        options={this.options()}
                        isClearable={true}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="d-block font-size-rg mb-0 font-sb">BOOK DATE</label>
                    <DatePicker
                      className="form-control cstm-mb-4"
                      selected={this.state.bookingData.date}
                      onChange={(date) => this.handleChangeDate(date)}
                      dateFormat="yyyy-MM-dd"
                      filterDate={(date) => this.getWeekdays(date)}
                      minDate={new Date()}
                      // maxDate={(new Date()).setDate((new Date()).getDate() + 90)}
                    />
                  </div>
                  <div className="w-100 text-right">
                      <button onClick={() => this.submitBooking()} className={`btn btn-primary mt-3 font-size-rg w-100 ${this.state.bookedSlots < 1 ? "d-none" : "d-block"}`} disabled={this.state.isLoading ? "disabled": ""}>
                          {
                              this.state.isLoading ? <div className="spinner-border text-secondary" role="status"><span className="sr-only">Loading...</span></div> : 'Book Now'
                          }
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      if (!hasCoworking) {
        return(
          <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-6 mx-auto pb-5">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={access} alt="Mask-Group" border="0" width="80%"/>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <h2>
                                You don't have access to this page
                              </h2>
                              <h5>
                                If you want to access this you must have a coworking plan first.
                              </h5>
                                <button className="font-size-rg mb-16 btn btn-lg btn-primary mt-2"><Link to="/dashboard">Back To Dashboard</Link></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
        )
      }
    }

    showMap = () => {
        if (this.state.lat) {
            return (<Map center={[this.state.lat, this.state.lng]} zoom={23} minZoom={5} className="location-map">
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[this.state.lat, this.state.lng]} ref={this.openPopup}>
                    <Popup>
                        {this.state.facilities.address}
                    </Popup>
                </Marker>
            </Map>)
        }
    }


    render() { 

        // if(this.state.isLoading){
        //     return (
        //         <>
        //             <ReactLoading type="bubbles" style={{
        //                 margin: "0 auto",
        //                 fill: "rgb(136, 136, 136)",
        //                 height: 127,
        //                 width: 127
        //             }} />
        //         </>
        //     )
        // }

        return (
            <>
                <Navbar />
                <div className="facility-book-show-container">
                    <div className="col-lg-8 p-0 mx-auto top-container" >
                      {
                        Object.keys(this.state.coworkingData).length !== 0 && this.showCoworkingPage(this.state.coworkingData.status)
                      }
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default CoworkingPage;