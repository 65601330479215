import Axios from "axios";

export const apiCaller = Axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

apiCaller.interceptors.request.use((config) => {
    config.headers = { ...config.headers, Authorization: `Bearer ${localStorage.getItem("token")}`}

    return config;
}, (error) => {
    return Promise.reject(error);
})

apiCaller.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, (error) => {
    console.log(error.response);
    if(error.response){
        if (error.response.status === 401) {
            localStorage.clear();
            return document.location.replace("/login");
        }
    }

    return Promise.reject(error);
});