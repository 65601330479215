import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import './Mails.css';
import { apiCaller } from "../../services/apiCaller";
import * as moment from "moment";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailLoading: true,
            mailData: {},
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    getClientMail() {
        apiCaller.get(`/getClientMails/${this.userData.client_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                this.setState({
                    mailLoading: false,
                    mailData: res.data.data
                })
            })
    }

    showClientMailTable() {
        if (!this.state.mailLoading) {
            return this.renderTableData();
        }
    }

    componentDidMount() {
        document.title = "vPortal | Mails";
        this.getClientMail();
    }

    renderTableData() {
        const tableData = this.state.mailData;
        let result = [];

        for (let i = 0; i < tableData.length; i++) {
            result.push(
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-light mb-3">
                            {/* <div class="card-header">Header</div> */}
                            <div className="card-body">
                                <div className="row m-0">
                                    <div className="col-md-9 p-0">
                                        <span className="d-block font-size-sm opacity-06">Sender Info</span>
                                        <label className="font-size-rg font-sb color-primary" dangerouslySetInnerHTML={{  __html: tableData[i].sender_info }} />
                                        <div className="row m-0 mb-3">
                                            <FiMapPin className="font-size-rg font-sb d-flex" />
                                            <label className="font-size-sm font-sb mb-0 d-block ml-2">
                                                {tableData[i].location_name}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md p-0 mb-2 ">
                                        <div>
                                            {this.translateStatus(tableData[i].status)}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row m-0 border-top">
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Category</span>
                                        <label className="font-size-rg font-sb">
                                            { tableData[i].category_name }
                                        </label>
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Received Time</span>
                                        <label className="font-size-rg font-sb">
                                            {moment(tableData[i].received_at).format("D MMM YYYY HH:mm")}
                                        </label>
                                    </div>
                                    <div className="col-md-3 p-0">
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Received By</span>
                                        <label className="font-size-rg font-sb">
                                            { tableData[i].created_by }
                                        </label>
                                    </div>
                                </div>
                                <div className="row m-0 border-top" >
                                        <h6>Picked Up Details:</h6>
                                </div>
                                {
                                    tableData[i].status === 1 ? 
                                    <div>
                             
                             <div className="row m-0 ">
                                 <div className="col-md-3 p-0">
                                     <span className="d-flex font-size-sm opacity-06">Picked Up By</span>
                                     <label className="font-size-rg font-sb">
                                         { tableData[i].recipient_name }
                                     </label>
                                 </div>
                                 <div className="col-md-3 p-0">
                                     <span className="d-flex font-size-sm opacity-06">Picked Up Time</span>
                                     <label className="font-size-rg font-sb">
                                         {moment(tableData[i].taken_by_client_at).format("D MMM YYYY HH:mm")}
                                     </label>
                                 </div>
                                 <div className="col-md-3 p-0">
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <span className="d-flex font-size-sm opacity-06">Passed By</span>
                                        <label className="font-size-rg font-sb">
                                            { tableData[i].forwarded_by }
                                        </label>
                                    </div>
                             </div>
                                    </div>
                             : <h7 style={{color:"red"}}> Not Picked Up Yet!</h7>
                                }
                              
                            
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (tableData.length < 1) {
            return <div className="row">
                <div className="col-12">
                    <div className="card bg-light mb-3">
                        {/* <div class="card-header">Header</div> */}
                        <div className="card-body">
                            <center>
                                <h3>No Data Available</h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        }

        return result;
    }

    translateStatus(status) {
        let res = '';
        if (status === 0) {
            res += 'Received by our staff'
            return (
            <div className="badge badge-pill badge-primary pt-2 font-size-rg">
            {res}
            </div>
            )
        }
        else if (status === 1) {
            res += 'Picked up by client'
            return (
            <div className="badge badge-pill badge-success pt-2 font-size-rg">
            {res}
            </div>

            )
            
        }
        else if (status === 2) {
            res += 'Forwarded to client';
        }
        else if (status === 3) {
            res += 'Rejected by our staff';
        }

    
    }

    loaderSpinner(){
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    render() {

        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3 h-100vh">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            <label className="font-size-rg mb-16 pt-1 color-primary">
                            <Link to="/dashboard">
                                <IoIosArrowBack className="mb-1" /> Back
                            </Link>
                            </label>
                            <br />
                            <label className="font-size-rg mb-16 pt-1">
                            Last mail(s)
                            </label>
                            {this.state.mailLoading ? this.loaderSpinner() :this.renderTableData()}
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
