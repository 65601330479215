import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    HashRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';
import PublicRoute from './routes/PublicRoutes';
import PrivateRoute from './routes/PrivateRoutes';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register';
import AccountVerification from './pages/AccountVerification/AccountVerification';
import ForgotPassword from './pages/ForgotPassword/Forgot';
import ResetPasswordPublic from './pages/ResetPassword-Public/ResetPasswordPublic';
import { verifyToken } from './services/authServices';
import './custom.css';
import ManageProfile from './pages/ManageProfile/ManageProfile';
import FacilitiesBooking from './pages/FacilitiesBooking/FacilitiesBooking';
import FacilitiesBookingShow from './pages/FacilitiesBooking/FacilitiesBookingShow';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Calls from './pages/Calls/Calls';
import Mails from './pages/Mails/Mails';
import Invoices from './pages/Invoices/Invoices';
import InvoiceDetails from './pages/Invoices/InvoiceDetails';
import PaymentResult from './pages/Payment/PaymentResult';
import Visitors from './pages/Visitors/Visitors';
import BookingHistory from './pages/BookingHistory/BookingHistory';
import BookingShare from './pages/Public/BookingShare';
import RewardPage from './pages/Reward/RewardPage';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CoworkingPage from './pages/Coworking/CoworkingPage';



function App() {

    // verify token on app load
    useEffect(() => {
        const tokenValidity = async () => {
            const checkToken = await verifyToken();
            if (checkToken.error && checkToken.response) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userData');
            }
        }

        tokenValidity();
    }, []);
    
    const auth = useSelector(state => state.auth);
    const { isAuthenticated } = auth;

    return (
        <Router>
            <Switch>
                <PublicRoute exact path='/login' component={Login} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/register' component={Register} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/verify' component={AccountVerification} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/forgot' component={ForgotPassword} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/reset' component={ResetPasswordPublic} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/booking-share/:bookingId' component={BookingShare} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/tnc' component={TermsAndConditions} isAuthenticated={isAuthenticated} />
                <PublicRoute exact path='/privacy' component={PrivacyPolicy} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/dashboard' component={Home}  isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/manage-profile' component={ManageProfile}  isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/facility-booking' component={FacilitiesBooking}  isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/facility-booking/:facilityId' component={FacilitiesBookingShow}  isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/change-password' component={ChangePassword}  isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/calls' component={Calls} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/mails' component={Mails} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/invoices' component={Invoices} isAuthenticated={isAuthenticated} />
                {/* <PrivateRoute exact path='/invoice/details/:invoiceId' component={InvoiceDetails} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/payment/success/:transactionId' component={PaymentResult} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/payment/pending/:transactionId' component={PaymentResult} isAuthenticated={isAuthenticated} /> */}
                <PrivateRoute exact path='/visitors' component={Visitors} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/booking-history' component={BookingHistory} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/reward' component={RewardPage} isAuthenticated={isAuthenticated} />
                <PrivateRoute exact path='/coworking' component={CoworkingPage} isAuthenticated={isAuthenticated} />
                <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
            </Switch>
        </Router>
    );
}

export default App;
