import React, { Component } from 'react';
import logo from "./logo-pure-white.svg";
import collapsibleIcon from './collapsible-icon.svg';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { HiOutlineUserCircle } from "react-icons/hi";
import swal from "sweetalert";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    handleLogout = () => {
        return swal({
            title: "Are you sure?",
            text:
                `Are you sure want to logout?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmBooking) => {
            if (confirmBooking) {
                localStorage.clear();
                return document.location.reload();
            }
        });
    }

    render() { 
        const toggleStyle = {
            backgroundImage: `url(${collapsibleIcon})`
        }

        return (
            <div>
                <nav className="navbar fixed-top navbar-expand-lg">
                    <Link className="navbar-brand" to="/dashboard">
                        <img src={logo} height="40" className="d-inline-block align-top" alt="" />
                    </Link>
                    <button className="navbar-toggler icon-nav pr-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        {/* <FaBars className="navbar-toggler-icon" /> */}
                        <span className="navbar-toggler-icon" style={toggleStyle} ></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown">
                                <a href="#foo" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <HiOutlineUserCircle style={{ 
                                        fontSize: '20px',
                                        marginTop: -4,
                                    }} /> My Account
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                <Link className="dropdown-item px-0 px-md-3 py-2" to="/booking-history">My Booking History</Link>
                                <Link className="dropdown-item px-0 px-md-3 py-2" to="/manage-profile">Manage Profile</Link>
                                <Link className="dropdown-item px-0 px-md-3 py-2" to="/change-password">Change Password</Link>
                                <a href="#foo" className="dropdown-item px-0 px-md-3 py-2" onClick={(e) => this.handleLogout()}>Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>
        );
    }
}

export default Navbar;