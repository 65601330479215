import React, { Component } from 'react';
import IconFb from "../../components/fb_icon.png";
import IconIg from "../../components/ig_icon.png";
import IconLi from "../../components/li_icon.png";
import IconYt from "../../components/yt_icon.png";

class SocialMediaButton extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 

        const link = [
          "https://www.facebook.com/vofficeindonesia/",
          "https://www.instagram.com/voffice/?hl=id",
          "https://id.linkedin.com/company/voffice-indonesia",
          "https://www.youtube.com/channel/UC6N5WQ9aOM85yDu9dmprCpA",
        ]; 
        return ( 
            <div>
                <div className="table-container card-container row card-container-m">
                    <div className="col-md-12 p-0">
                        <label className="font-size-rg pt-1 mb-16">
                            Connect With Us
                        </label>
                        {/* <span className="d-block mb-16 font-size-sm opacity-07">
                            Keep updated on vOffice's promotions
                        </span> */}
                    </div>
                    <div className="col p-0 connect-crd">
                        <a href={link[0]} target="_blank" rel="noopener noreferrer">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img alt="Social Account" src={IconFb} width="auto" height="24px" />
                                    <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col p-0 connect-crd">
                        <a href={link[1]} target="_blank" rel="noopener noreferrer">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img alt="Social Account" src={IconIg} width="auto" height="24px"/>
                                    <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">@voffice</label>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col p-0 connect-crd">
                        <a href={link[2]} target="_blank" rel="noopener noreferrer">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img alt="Social Account" src={IconLi} width="auto" height="24px"/> 
                                    <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col p-0 connect-crd">
                        <a href={link[3]} target="_blank" rel="noopener noreferrer">
                            <div className="card text-center">
                                <div className="card-body">
                                    <img alt="Social Account" src={IconYt} width="auto" height="24px"/> 
                                    <label className="mb-0 ml-2 d-md-block d-lg-inline d-none opacity-07 font-size-sm">vOffice Indonesia</label>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default SocialMediaButton;