import React, { Component } from 'react';
import './QuotaCard.css';

class QuotaCard extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div className="col-md-6-quota quota-crd">
                <div className={`border-radius-15 font-size-sm bg-img ${this.props.quotaBg}`}>
                    <div className={`row m-0 quota-card ${this.props.quotaClass}`}>
                        <div className="mr-1">
                            <label className="d-block font-size-vbig font-weight-bold my-auto">
                                {this.props.quotaValue}</label>
                        </div>
                        <div>
                            <label className="d-block font-weight-bold mb-0">{this.props.quotaName}</label>
                            <span className="opacity-06">Hour(s) left</span>
                        </div>
                    </div>
                    {/* <img src={this.props.imgBg} className="img-quota" /> */}
                    
                </div>
            </div>
        );
    }
}
 
export default QuotaCard;