import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { apiCaller } from "../../services/apiCaller";

import ReactLoading from "react-loading";
import FloatingButton from "../../components/FloatingButton";
import { ImCheckmark, ImCross} from "react-icons/im";
import NumberFormat from "react-number-format";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTrans: {},
            isLoading: true,
        };
        this.userData = JSON.parse(localStorage.getItem("userData"));
    }

    componentDidMount() {
        document.title = "vPortal | Payment Successfully";
        this.getData();
    }

    loaderSpinner() {
        return (
            <div className="col-2 mx-auto mt-5 text-center">
                <ReactLoading type="bubbles" color="#888888" />
            </div>
        );
    }

    getData(){

        const { match: { params } } = this.props;

        apiCaller.get(`/midtrans/payment/getTransaction/${params.transactionId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            this.setState({
                dataTrans: res.data.payload,
                isLoading: false
            })
        })
    }

    getStatus = (status) => {
        const fontSizeVB = {
            fontSize: "4rem",
        };

        if (status === 200 || status === "200") {
            return(
                <center className="text-success mb-3">
                <ImCheckmark style={fontSizeVB} className="mt-3 mb-5" />
                <h2>Payment Successfully</h2>
                </center>
            );
        } else if (status === 201 || status === "201") {
            return(
                <center className="text-warning mb-3">
                <ImCheckmark style={fontSizeVB} className="mt-3 mb-5" />
                <h2>Payment Pending</h2>
                </center>
            );
        } else {
            return(
                <center className="text-danger mb-3">
                <ImCross style={fontSizeVB} className="mt-3 mb-5" />
                <h2>Payment Failed</h2>
                </center>
            );
        }
        
    }

    getPaymentType = (payment_type) => {
        const detailTransaction = {
            background: "#faebd78c",
            borderRadius: 15,
        };

        if (payment_type === "credit_card") {
            return(
                <div className="row font-size-rg mt-5 mx-auto p-3" style={detailTransaction}>
                    <div className="col-6 p-0 mb-3 opacity-06">
                        Order ID
                    </div>
                    <div className="col-6 p-0 mb-3 text-right">
                        {this.state.dataTrans.order_id}
                    </div> 
                    <div className="col-6 p-0 opacity-06 mt-2">
                        Payment type
                    </div>
                    <div className="col-6 p-0 text-right mt-2">
                        {String(this.state.dataTrans.payment_type).substring(0,1).toUpperCase()}{String(this.state.dataTrans.payment_type).replace("_"," ").slice(1)}
                    </div>                                        
                    <div className="col-6 p-0 opacity-06 mt-2">
                        Bank
                    </div>
                    <div className="col-6 p-0 text-right mt-2">
                        {this.state.dataTrans.bank.toUpperCase()}
                    </div>                                    
                    <div className="col-6 p-0 mt-3">
                        Amount Paid
                    </div>
                    <div className="col-6 p-0 mt-3 text-right">
                        <NumberFormat value={Math.floor(this.state.dataTrans.gross_amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                    </div>
                </div>
            );
        } else if (this.state.dataTrans.va_numbers) {
            return (
                <div className="row font-size-rg mt-5 mx-auto p-3" style={detailTransaction}>
                    <div className="col-6 p-0 mb-3 opacity-06">
                        Order ID
                    </div>
                    <div className="col-6 p-0 mb-3 text-right">
                        {this.state.dataTrans.order_id}
                    </div> 
                    <div className="col-6 p-0 opacity-06 mt-2">
                        Payment type
                    </div>
                    <div className="col-6 p-0 text-right mt-2">
                        {String(this.state.dataTrans.payment_type).substring(0,1).toUpperCase()}{String(this.state.dataTrans.payment_type).replace("_"," ").slice(1)}
                    </div>                                        
                    <div className="col-6 p-0 opacity-06 mt-2">
                        Bank
                    </div>    
                    <div className="col-6 p-0 text-right mt-2">
                        {this.state.dataTrans.va_numbers[0].bank.toUpperCase()}
                    </div>                           
                    <div className="col-6 p-0 mt-3">
                        Amount Paid
                    </div>
                    <div className="col-6 p-0 mt-3 text-right">
                        <NumberFormat value={Math.floor(this.state.dataTrans.gross_amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                    </div>
                    
                </div>
            );
        } else {
            return(
                <div className="row font-size-rg mt-5 mx-auto p-3" style={detailTransaction}>
                    <div className="col-6 p-0 mb-3 opacity-06">
                        Order ID
                    </div>
                    <div className="col-6 p-0 mb-3 text-right">
                        {this.state.dataTrans.order_id}
                    </div> 
                    <div className="col-6 p-0 opacity-06 mt-2">
                        Payment type
                    </div>
                    <div className="col-6 p-0 text-right mt-2">
                        {String(this.state.dataTrans.payment_type).substring(0,1).toUpperCase()}{String(this.state.dataTrans.payment_type).replace("_"," ").slice(1)}
                    </div>                         
                    <div className="col-6 p-0 mt-3">
                        Amount Paid
                    </div>
                    <div className="col-6 p-0 mt-3 text-right">
                        <NumberFormat value={Math.floor(this.state.dataTrans.gross_amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                    </div>
                    
                </div>
            );
        }
    }

    displayPaymentResult = () => {
        const maxWidthCard = {
            maxWidth: "550px",
            overflow: "hidden",
            position: "relative",
        };

        const graphic1 = {
            backgroundColor: "#00cc9917!important",
            width: "500px",
            height: "500px",
            position: "absolute",
            top: "-310px",
            borderRadius: "50%",
        };
        
        let result = <div className="card mx-auto" style={maxWidthCard}>
            <div className="card-body mt-3 mb-5">
                {this.state.dataTrans.status_code ?  this.getStatus(this.state.dataTrans.status_code) : ''}
                {this.state.dataTrans ? this.getPaymentType(this.state.dataTrans.payment_type): ''}
                <center>
                    <div className="font-size-rg mb-16 pt-1 btn btn-success mt-5"><Link to="/dashboard">Back To Dashboard</Link></div>
                </center>
                <div className={this.state.dataTrans.status_code === 200 ? "bg-success opacity-01":"bg-warning opacity-01"} style={graphic1}>
                </div>
            </div>
        </div>
        
        return result;
    }

    render() {
        
        return (
            <>
                <Navbar />
                <div className="top-container row m-0 mb-3 p-3">
                    <div className="col-md-12 p-0">
                        <div className="container p-0">
                            {this.state.isLoading ? this.loaderSpinner() : this.displayPaymentResult() }
                        </div>
                    </div>
                </div>
                <FloatingButton />
            </>
        );
    }
}

export default Home;
